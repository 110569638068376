import { createBike, updateBike } from '../../store/userSlice';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { IconPlus } from '@tabler/icons';
import { selectConfig } from '../../store/appSlice';
import { useAppSelector } from '../../hooks/useAppSelector';
import * as Yup from 'yup';
import React, { useState, useEffect } from 'react';
import useConfirmationModal from '../../hooks/useModalConfirmation';
import { useAppDispatch } from '../../hooks/useAppDispatch';

const ValidationSchema = Yup.object().shape( {
    name: Yup.string()
        .max( 50, 'Too Long!' )
        .required( 'Campo obligatorio' )
} );

export const AddBike = ( props: any ) => {

    const dispatch = useAppDispatch();
    const { declineModal, confirmModal } = useConfirmationModal();
    const { constants } = useAppSelector( selectConfig );
    const [ loading, setLoading ] = useState( false );

    const submitBike = async ( values: any ) => {
        if ( props._id ) {
            setLoading( true );
            await dispatch( updateBike( values ) );
            setLoading( false );
            confirmModal();
        } else {
            setLoading( true );
            await dispatch( createBike( values ) );
            setLoading( false );
            confirmModal();
        }
    }

    const initialValues = {
        name: '',
        type: 10,
        tireType: 30,
        terrainType: 30,
        condition: 2,
        initialKms: 0,
        weight: 0,
        ...props
    };

    return <>
        <div className="modal-header">
            <h5 className="modal-title">Añadir bicicleta</h5>
            <button type="button" className="btn-close" aria-label="Close" onClick={declineModal} disabled={loading}></button>
        </div>
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={submitBike}

        >
            {( { errors } ) => (
                <Form>
                    <div className="modal-body">

                        <div className='row'>
                            <div className="form-group mb-3 col-12">
                                <label className="form-label">Nombre</label>
                                <Field id="name" name="name" type="text" className={`form-control ${errors.name && 'is-invalid'}`} autoComplete='off' placeholder="Nombre" />
                                <div className="invalid-feedback">{'' + errors.name}</div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="form-group mb-3 col-6">
                                <label className="form-label">Tipo</label>
                                <Field as="select" id="type" name="type" className="form-select" >
                                    {constants.bikeTypes.map( ( bikeType: any ) =>
                                        <option key={`type${bikeType.id}`} value={bikeType.id}>{bikeType.name}</option>
                                    )}
                                </Field>
                            </div>
                            <ConditionalFormElement>
                                <div className="form-group mb-3 col-6">
                                    <label className="form-label">Ruedas</label>
                                    <Field as="select" id="tireType" name="tireType" className="form-select" >
                                        {constants.tireTypes.map( ( tireType: any ) =>
                                            <option key={`type${tireType.id}`} value={tireType.id}>{tireType.name}</option>
                                        )}
                                    </Field>
                                </div>
                            </ConditionalFormElement>
                        </div>
                        <ConditionalFormElement>
                            <div className='row'>
                                <div className="form-group mb-3 col-6">
                                    <label className="form-label">Estado de mantenimiento</label>
                                    <Field as="select" id="condition" name="condition" className="form-select" >
                                        {constants.bikeCondition.map( ( status: any ) =>
                                            <option key={`type${status.id}`} value={status.id}>{status.name}</option>
                                        )}
                                    </Field>
                                </div>

                                <div className="form-group mb-3 col-6">
                                    <label className="form-label">Terreno habitual</label>
                                    <Field as="select" id="terrainType" name="terrainType" className="form-select" >
                                        {constants.terrainTypes.map( ( terrainType: any ) =>
                                            <option key={`type${terrainType.id}`} value={terrainType.id}>{terrainType.name}</option>
                                        )}
                                    </Field>
                                </div>

                            </div>

                            <div className='row'>
                                <div className="form-group mb-3 col-6">
                                    <label className="form-label">Peso</label>
                                    <Field type="number" className="form-control" id="weight" name="weight" placeholder="0" />
                                </div>
                                <div className="form-group mb-3 col-6">
                                    <label className="form-label">Kms iniciales</label>
                                    <Field type="number" className="form-control" id="initialKms" name="initialKms" placeholder="0" />
                                </div>
                            </div>
                        </ConditionalFormElement>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-link link-secondary" onClick={declineModal} disabled={loading}>
                            Cancelar
                        </button>
                        <div className='ms-auto'>
                            <button type="submit" className={`btn btn-cyan ms-auto`} data-bs-dismiss="modal"  >
                                <IconPlus /> Guardar
                            </button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    </>

}

const ConditionalFormElement = ( props: any ) => {

    const {
        values: { type }
    } = useFormikContext();

    const [ showElement, setShowElement ] = useState<boolean>();

    useEffect( () => {
        setShowElement( +type !== 40 );
    }, [ type ] )

    if ( showElement ) {
        return props.children;
    } else {
        return null
    }
}
