import useConfirmationModal from '../../hooks/useModalConfirmation';

export const Terms = () => {

    const { confirmModal } = useConfirmationModal();

    return <>
        <div className="modal-header">
            <h5 className="modal-title">Términos y condiciones de uso</h5>
        </div>
        <div className="modal-body">
            <p>No se permite el uso de lenguaje obsceno o irrespetuoso en xclegends.com (de ahora en adelante XCL) ni el acoso o las faltas de respeto a otros usuarios.</p>
            <p>No se permiten datos o actividades deportivas que por cualquier motivo puedan estar relacionadas con actividades ilegales, delictivas o dañinas.</p>
            <p>No se permite monetizar el uso de la aplicación de ninguna forma ni vender credenciales de acceso a terceros sin el consentimiento de XCL.</p>
            <p>XCL no se hace responsable de posibles datos filtrados de los usuarios ante ciberataques.</p>
            <p>XCL se reserva el derecho a eliminar cualquier actividad o usuario en cualquier momento de forma unilateral y sin previo aviso.</p>
            <p>XCL se reserva el derecho a rescindir en cualquier momento su actividad y a eliminar completamente todos los datos guardados sin previa notificación al usuario.</p>
            <p>XCL no puede asegurar un servicio 100% ininterrumpido y no se hará responsable de eventuales cortes de servicio ante problemas técnicos de cualquier tipo.</p>
            <p>Los datos de un usuario dado de baja o bloqueado por la propia plataforma serán eliminados completamente y no recuperables.</p>
            <p><b>El registro en la aplicación no da al usuario ningún derecho ni propiedad sobre XCL más allá de sus datos personales.</b></p>
            <p><b>Al registrarse el usuario acepta todas las condiciones mencionadas anteriormente y a hacer un uso legal y responsable de la aplicación.</b></p>
            <br /><br />
            <p>Sobre <b>XCL</b>:</p>
            <p>Nos comprometemos a hacer un uso ético de los datos personales de los usuarios, que solo serán usados dentro de la propia plataforma, y que bajo ningún concepto serán compartidos con terceros ni comercializados de forma expresa.</p>
            <p>Tu privacidad es importante para nosotros, por lo que nos comprometemos a proteger tus datos todo lo posible. Además nos tomamos la ciberseguridad muy en serio y hacemos todo lo posible para que la plataforma sea segura para ti.</p>
            <p>Además nos compromentemos a eliminar tus datos completamente si en algún momento deseas no seguir siendo parte de la aplicación.</p>

        </div>
        <div className="modal-footer">
            <button className={`btn btn-cyan ms-auto`} data-bs-dismiss="modal" onClick={confirmModal} >Cerrar</button>
        </div>
    </>

}
