import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { AppThunk, RootState } from "./index";

export interface ActivitiesState {
  activity: any;
  limit: number;
  list: any[];
  loading: boolean;
  start: number;
  total: number;
}

export const initialState: ActivitiesState = {
  activity: null,
  limit: 10,
  list: [],
  loading: false,
  start: 0,
  total: 0,
};

export const createActivity = createAsyncThunk(
  "activities/add",
  async (activityData: any, { getState }) => {
    const state: any = getState();
    const jwt = state.auth.jwt;
    const { name, bike, file } = activityData;
    let data = new FormData();
    data.append("name", name);
    data.append("files", file);
    if (!!bike) {
      data.append("bike", bike);
    }
    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}/api/activities`,
      data,
      { withCredentials: true, headers: { "x-token": jwt } }
    );
    return response.data;
  }
);

export const updateActivity = createAsyncThunk(
  "activities/update",
  async (activityData: any, { getState }) => {
    const state: any = getState();
    const jwt = state.auth.jwt;
    const { name, bikeId, activityId } = activityData;
    let data = new FormData();

    if (name) {
      data.append("name", name);
    }
    if (bikeId) {
      data.append("bikeId", bikeId);
    }

    const response = await axios.put(
      `${process.env.REACT_APP_API_HOST}/api/activities/${activityId}`,
      data,
      { withCredentials: true, headers: { "x-token": jwt } }
    );
    return response.data;
  }
);

export const getActivity = createAsyncThunk(
  "activities/getSingle",
  async (activityId: any, { getState }) => {
    const state: any = getState();
    const jwt = state.auth.jwt;
    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}/v2/activities/${activityId}`,
      { withCredentials: true, headers: { "x-token": jwt } }
    );
    return response.data;
  }
);

export const deleteActivity = createAsyncThunk(
  "activities/delete",
  async (activityId: any, { getState }) => {
    const state: any = getState();
    const jwt = state.auth.jwt;
    const response = await axios.delete(
      `${process.env.REACT_APP_API_HOST}/api/activities/${activityId}`,
      { withCredentials: true, headers: { "x-token": jwt } }
    );
    return response.data;
  }
);

export const getActivities =
  (filter?: any): AppThunk =>
  (dispatch, getState) => {
    const state: any = getState();
    const jwt = state.auth.jwt;
    const start = filter ? filter.start : state.activities.start;
    const limit = filter ? filter.limit : state.activities.limit;
    dispatch(loading({ start, limit }));
    const params = new URLSearchParams({ start, limit });
    axios
      .get(
        `${process.env.REACT_APP_API_HOST}/api/activities?${params.toString()}`,
        { withCredentials: true, headers: { "x-token": jwt } }
      )
      .then(function (response: any) {
        dispatch(loadList({ start, limit, list: response.data.activities }));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

export const activitiesSlice = createSlice({
  name: "activities",
  initialState,
  reducers: {
    loading: (state, action) => {
      state.loading = true;
      state.start = action.payload.start;
      state.limit = action.payload.limit;
    },
    loadList: (state, action: PayloadAction<any>) => {
      if (
        state.start === action.payload.start &&
        state.limit === action.payload.limit
      ) {
        state.list = action.payload.list;
        state.loading = false;
      }
    },
    resetList: (state) => {
      state.start = initialState.start;
      state.limit = initialState.limit;
      state.total = initialState.total;
      state.list = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActivity.fulfilled, (state, action: any) => {
        state.activity = action.payload;
      })
      .addCase(updateActivity.fulfilled, (state, action: any) => {
        state.activity = action.payload;
      });
  },
});

export const { loadList, loading, resetList } = activitiesSlice.actions;

export const selectList = (state: RootState) => state.activities.list;
export const selectActivitiesListTotal = (state: RootState) =>
  state.activities.total;
export const selectCurrentActivity = (state: RootState) =>
  state.activities.activity;
export const selectPagination = (state: RootState) => ({
  start: state.activities.start,
  limit: state.activities.limit,
});
export const selectActivitiesLoading = (state: RootState) =>
  state.activities.loading;

export default activitiesSlice.reducer;
