
import { Modal as BootstrapModal } from 'react-bootstrap';
import { EditActivity } from '../../containers/activities/EditActivity';
import { Terms } from '../../containers/auth/Terms';
import { AddBike } from '../../containers/settings/AddBike';
import { AddActivity } from '../../containers/activities/AddActivity';
import useConfirmationModal from '../../hooks/useModalConfirmation';
import { MODALS } from '../../util/constants';


const Modal = () => {

    const { isOpened, title, description, okButtonText, cancelButtonText, modalType, modalProps, confirmModal, declineModal } = useConfirmationModal();

    let modalContent = null;
    let actionClassName = '';
    let sizeClassName = '';

    switch ( modalType ) {
        case MODALS.MODALS_TYPES.ADD_ACTIVITY:
            modalContent = <AddActivity />;
            sizeClassName = '';
            break;
        case MODALS.MODALS_TYPES.EDIT_ACTIVITY:
            modalContent = <EditActivity  {...modalProps} />;
            sizeClassName = '';
            break;
        case MODALS.MODALS_TYPES.ADD_BIKE:
            modalContent = <AddBike {...modalProps} />;
            sizeClassName = '';
            break;
        case MODALS.MODALS_TYPES.TERMS:
            modalContent = <Terms />;
            sizeClassName = '';
            break;
        case MODALS.MODALS_TYPES.GENERIC_CONFIRMATION_WARNING:
            actionClassName = 'btn-danger';
            sizeClassName = 'modal-md';
            break;
        case MODALS.MODALS_TYPES.GENERIC_CONFIRMATION:
            actionClassName = 'btn-cyan';
            sizeClassName = 'modal-md';
            break;
        case MODALS.MODALS_TYPES.GENERIC_INFO:
        default:
            actionClassName = 'btn-cyan';
            sizeClassName = 'modal-sm';
    }

    return <BootstrapModal id='app-modal' show={isOpened} animation className={`modal modal-blur ${sizeClassName}`} tabIndex={-1} aria-modal={"true"} role={"dialog"} backdrop={'static'} >
        {modalContent ?
            modalContent
            : <>
                <div className="modal-body">
                    <div className="modal-title">{title}</div>
                    <div>{description}</div>
                </div>
                <div className="modal-footer">
                    {modalType !== MODALS.MODALS_TYPES.GENERIC_INFO &&
                        <button type="button" className="btn btn-link link-secondary me-auto" data-bs-dismiss="modal" onClick={() => declineModal()}>{cancelButtonText}</button>}
                    <button type="button" className={`btn ${actionClassName}`} data-bs-dismiss="modal" onClick={() => confirmModal()}>{okButtonText}</button>
                </div>
            </ >}
    </BootstrapModal>
}

export default Modal;