import { LatLngTuple } from "leaflet";
import { PropsWithChildren } from "react";
import { MapContainer } from "react-leaflet";
import { TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { OpenMapContent } from "./OpenMapContent";

interface OpenMapProps {
  coords: number[][];
  color: string;
  points?: [[number, number]];
  fitMapToContent?: boolean;
}

export const OpenMap = (props: OpenMapProps & PropsWithChildren) => {
  const center: LatLngTuple = [41.3874, 2.1686];

  // https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
  // https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}
  // https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png
  // https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}
  // https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png
  // https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png

  return (
    <MapContainer
      center={center}
      bounceAtZoomLimits
      zoom={13}
      scrollWheelZoom={false}
      style={{ width: "100%", height: "100%" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
      />
      {props.coords.length > 0 && <OpenMapContent {...props} />}
    </MapContainer>
  );
};
