import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import { selectAuthJWT, selectAuthUserId } from "../../store/authSlice";
import axios from "axios";
import { LineChart } from "../charts/LineChart";
import { COLORS } from "../../util/constants";

interface IPeriodStatsProps {
  compactMode?: boolean;
  title?: string;
  days: number;
}

const PeriodStats = (props: IPeriodStatsProps) => {
  const userId = useAppSelector(selectAuthUserId);
  const jwt = useAppSelector(selectAuthJWT);
  const [stats, setStats] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_HOST}/v2/users/${userId}/stats?days=${props.days}`,
        { withCredentials: true, headers: { "x-token": jwt } }
      );
      setStats(response.data);
    };
    fetchData();
  }, [jwt, userId, props.days]);

  const renderMetrics = () => {
    const expandedStyles = "col-6 col-md-3";
    const compactStyles = "col-12 col-md-6";
    return (
      <>
        <div className={props.compactMode ? compactStyles : expandedStyles}>
          <span className="subheader me-2">Distancia</span>
          <span className="h5">{stats?.totalDistance} km</span>
        </div>
        <div className={props.compactMode ? compactStyles : expandedStyles}>
          <span className="subheader me-2">Desnivel</span>
          <span className="h5 mb-1">{stats?.totalClimb} m</span>
        </div>
        {!props.compactMode && (
          <>
            <div className="col-6 col-md-3">
              <span className="subheader me-2">Tiempo total</span>
              <span className="h5 mb-1">
                {Math.round((stats?.totalTime || 0) / 1000 / 60 / 60)} h.
              </span>
            </div>
            <div className="col-6 col-md-3">
              <span className="subheader me-2">Actividades</span>
              <span className="h5">{stats?.activitiesCount}</span>
            </div>
          </>
        )}
      </>
    );
  };

  if (!stats || stats.activitiesRecord.length < 2) {
    return null;
  }

  return (
    <div className="row row-deck row-cards">
      <div className="col-sm-12 col-lg-12 mb-3">
        <div className="col-12">
          <div className="card">
            <div className="row row-0" style={{ position: "relative" }}>
              <div className="card-body pb-0">
                <div className="row">
                  <div className=" col-12">
                    <div className="row">
                      <div className="col-sm-12 col-md-4 text-left">
                        <div className="align-items-center ">
                          <h3>
                            {props.title
                              ? props.title
                              : `Últimos ${props.days} días`}
                          </h3>
                        </div>
                      </div>
                      <div className="row col-md-12 col-lg-8">
                        {renderMetrics()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                {stats && (
                  <LineChart
                    series={[
                      {
                        id: "distanceProgression",
                        name: "Distancia acumulada",
                        color: "#42B777",
                        data: stats
                          ? stats.activitiesRecord.map(
                              (act: any) => act.distanceProgression
                            )
                          : [],
                      },
                      {
                        id: "climbProgression",
                        name: "Ascenso acumulado",
                        color: COLORS.METRICS.ELEVATION,
                        data: stats
                          ? stats.activitiesRecord.map(
                              (act: any) => act.climbProgression
                            )
                          : [],
                      },
                      {
                        id: "distance",
                        name: "Distancia",
                        color: "#4BCAD255",
                        data: stats
                          ? stats.activitiesRecord.map(
                              (act: any) => act.distance
                            )
                          : [],
                      },
                    ]}
                    curve="smooth"
                    categories={stats.activitiesRecord.map(
                      (act: any) => act.formattedDate
                    )}
                    tooltip={true}
                    width="100%"
                    height="100px"
                    dataLabels={false}
                    onMouseOut={() => {}}
                    onMouseOver={() => {}}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeriodStats;
