import { selectLogged, selectDestionationPath, setPasswordAsync, selectAuthLoading } from '../../store/authSlice';
import { useAppSelector } from '../../hooks/useAppSelector';
import { Redirect } from 'react-router';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/useAppDispatch';

export const ResetPassword = ( props: any ) => {

    const code = props.match.params.code;

    const dispatch = useAppDispatch();
    const logged = useAppSelector( selectLogged );
    const loading = useAppSelector( selectAuthLoading );
    const destinationPath = useAppSelector( selectDestionationPath );
    const [ submitted, setSubmited ] = useState( false );
    const [ formError, setFormError ] = useState( { element: '', message: '' } );

    const submit = async ( event: any ) => {

        event.preventDefault();
        if ( event.target.password.value !== event.target.password2.value ) {
            setFormError( { element: 'All', message: 'Las contraseñas deben ser iguales' } );
            return;
        }

        if ( event.target.password.value.length < 8 ) {
            setFormError( { element: 'password', message: 'La contraseña debe contener 8 carácteres como mínimo' } );
            return;
        }

        const passwordData = {
            password: event.target.password.value,
            verificationCode: code
        }

        await dispatch( setPasswordAsync( passwordData ) );
        setSubmited( true );

    };

    if ( logged ) {
        return <Redirect to={destinationPath ? destinationPath : '/timeline'} />
    }

    if ( !submitted ) {
        return <div className='page page-center'>
            <div className='container-tight py-4'>
                <div className='text-center mb-4'>
                    <a href='.'><img src='./static/logo.svg' height='36' alt='' /></a>
                </div>
                <form className='card card-md' autoComplete='off' onSubmit={( e ) => submit( e )}>
                    <div className='card-body'>
                        <h2 className='card-title text-center mb-4'>Cambiar contraseña</h2>
                        {formError.element !== '' &&
                            <div className='mt-3'>
                                <div className="alert alert-warning" role="alert">
                                    <h4 className="alert-title">Atención!</h4>
                                    <div className="text-muted">{formError.message}</div>
                                </div>
                            </div>}
                        <div className='mb-3'>
                            <label className='form-label'>Contraseña</label>
                            <div className='input-group input-group-flat'>
                                <input id='password' type='password' className='form-control' placeholder='Contraseña' autoComplete='off' defaultValue='' disabled={loading} />
                            </div>
                        </div>
                        <div className='mb-2'>
                            <label className='form-label'>Confirmar contraseña</label>
                            <div className='input-group input-group-flat'>
                                <input id='password2' type='password' className='form-control' placeholder='Contraseña' autoComplete='off' defaultValue='' disabled={loading} />
                            </div>
                        </div>
                        <div className='form-footer'>
                            <button type='submit' className='btn btn-cyan w-100' disabled={loading}>Cambiar</button>
                        </div>
                        <div className='text-center mt-3'><Link to='/login'>Volver</Link></div>
                    </div>
                </form>
            </div>
        </div>
    } else {
        return <div className='page page-center'>
            <div className='container-tight py-4'>
                <div className='text-center mb-4'>
                    <a href='.'><img src='./static/logo.svg' height='36' alt='' /></a>
                </div>
                <div className='card card-md'>
                    <div className='card-body'>
                        <h2 className='card-title text-center mb-4'>Contraseña cambiada</h2>
                        <p>Ya puedes inciar sesión de nuevo.</p>

                        <div className='form-footer'>
                            <Link to='/login' type='submit' className='btn btn-cyan w-100' >Iniciar sesión</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }



}
