export const MODALS = {
  MODALS_TYPES: {
    GENERIC_CONFIRMATION: "CONFIRMATION",
    GENERIC_CONFIRMATION_WARNING: "CONFIRMATION_WARNING",
    GENERIC_INFO: "INFO",
    ADD_ACTIVITY: "ADD_ACTIVITY",
    EDIT_ACTIVITY: "EDIT_ACTIVITY",
    ADD_BIKE: "ADD_BIKE",
    TERMS: "TERMS",
  },
};

export const METRICS = {
  HR: "HR",
  TEMPERATURE: "TEMPERATURE",
  CADENCE: "CADENCE",
  ELEVATION: "ELEVATION",
};

export const COLORS = {
  MAP_LINE: "#42B777",
  METRICS: {
    HR: "#42B777",
    SLOPE: "#AECCBA",
    CADENCE: "#88E6E6",
    POWER: "#4BCAD2",
    TEMPERATURE: "#198BB4",
    ELEVATION: "#006A95",
  },
  SLOPE: {
    UPHILL: "#78C1C2",
    DOWNHILL: "#B2E4CC",
    FLAT: "#E5FADC",
  },
};

export enum VISIBILITY {
  PUBLIC = "public",
  FRIENDS = "friends",
  PRIVATE = "private",
}
