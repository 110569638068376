import { configureStore, ThunkAction, Action, getDefaultMiddleware, Store, combineReducers } from '@reduxjs/toolkit';
import { initialState as activitiesInitialState } from './activitiesSlice';
import { initialState as appInitialState } from './appSlice';
import { initialState as authInitialState } from './authSlice';
import { initialState as usersInitialState } from './userSlice';
import { initialState as segmentsInitialState } from './segmentsSlice';
import activitiesReducer from './activitiesSlice';
import appReducer from './appSlice';
import authReducer from './authSlice';
import userReducer from './userSlice';
import segmentsReducer from './segmentsSlice';

export interface ThunkExtraArguments {
  store: Store;
}

const thunkArguments = {} as ThunkExtraArguments;

const customizedMiddleware = getDefaultMiddleware( ( {
  thunk: {
    extraArgument: thunkArguments
  }
} ) );

export const storeReducers = {
  activities: activitiesReducer,
  app: appReducer,
  auth: authReducer,
  segments: segmentsReducer,
  user: userReducer
};

export const appReducers = combineReducers( storeReducers );

export const storeInitialState = {
  activities: activitiesInitialState,
  app: appInitialState,
  auth: authInitialState,
  segments: segmentsInitialState,
  users: usersInitialState,
}

export const store = configureStore( {
  reducer: storeReducers,
  middleware: customizedMiddleware
} );

thunkArguments.store = store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
