import { useAppSelector } from './useAppSelector';
import { confirmationModalThunkActions } from '../store/appSlice';
import { confirm as confirmAction, decline as declineAction } from '../store/appSlice';
import { RootState } from '../store/index';
import { useAppDispatch } from './useAppDispatch';

function useConfirmationModal() {

    const dispatch = useAppDispatch();

    const isOpened = useAppSelector( ( state: RootState ) => state.app.confirmationModal?.isOpened );
    const title = useAppSelector( ( state: RootState ) => state.app.confirmationModal?.title );
    const description = useAppSelector( ( state: RootState ) => state.app.confirmationModal?.description );
    const okButtonText = useAppSelector( ( state: RootState ) => state.app.confirmationModal?.okButtonText );
    const cancelButtonText = useAppSelector( ( state: RootState ) => state.app.confirmationModal?.cancelButtonText );
    const modalType = useAppSelector( ( state: RootState ) => state.app.confirmationModal?.modalType );
    const modalProps = useAppSelector( ( state: RootState ) => state.app.confirmationModal?.modalProps );

    const openGenericModal = async ( title = '', description = '', okButtonText = '', cancelButtonText = '', modalType = '' ) => {
        const modalContent = {
            title,
            description,
            okButtonText,
            cancelButtonText,
            modalType
        };
        const dispatchResult = await dispatch( confirmationModalThunkActions.openGenericModalAction( modalContent ) );
        return dispatchResult.payload;
    }

    const openModalWithComponent = async ( componentName: string, modalProps?: any ) => {
        //const payload = await dispatch( openModalComponent( { componentName, modalProps } ) );
        const dispatchResult = await dispatch( confirmationModalThunkActions.openModalComponentAction( { componentName, modalProps } ) );
        return dispatchResult.payload;
    }

    const confirmModal = () => {
        return dispatch( confirmAction() );
    }

    const declineModal = () => {
        return dispatch( declineAction() );
    }

    return {
        isOpened,
        title,
        description,
        okButtonText,
        cancelButtonText,
        modalType,
        modalProps,
        openGenericModal,
        openModalWithComponent,
        confirmModal,
        declineModal
    }
}

export default useConfirmationModal;