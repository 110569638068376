import { RouteComponentProps } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import {
  selectAuthUser,
  selectAuthUserId,
  selectJWTToken,
  selectLogged,
} from "../../store/authSlice";
import { Spinner } from "../../components/spinner/Spinner";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import axios from "axios";
import { getUser, updateUser } from "../../store/userSlice";

export const GarminConnection = (props: RouteComponentProps) => {
  const dispatch = useAppDispatch();
  const logged = useAppSelector(selectLogged);
  const userId = useAppSelector(selectAuthUserId);
  const params = new URLSearchParams(props.location.search);
  const oauthToken = params.get("oauth_token");
  const oauthVerifier = params.get("oauth_verifier");
  const [connectionEnded, setConnectionEnded] = useState<boolean>(false);
  const user = useAppSelector(selectAuthUser);

  const jwt = useAppSelector(selectJWTToken);

  useEffect(() => {
    const saveGarminConnection = async (userId: number) => {
      axios
        .post(
          `${process.env.REACT_APP_API_HOST}/api/connections/garmin/access/${oauthToken}/${oauthVerifier}`,
          null,
          { withCredentials: true, headers: { "x-token": jwt } }
        )
        .then((result) => {
          dispatch(getUser(userId));
          setConnectionEnded(true);
        })
        .catch((e) => {
          console.log("ERROR connecting with Garmin");
        });
      let garminConnectionData = { ...(user.connections?.garmin || {}) };
      await dispatch(
        updateUser({
          userId,
          userData: {
            connections: {
              garmin: { ...garminConnectionData, oauthToken, oauthVerifier },
            },
          },
        })
      );
    };
    if (!!userId && !!oauthVerifier && !!oauthToken && logged) {
      saveGarminConnection(userId);
    }
  }, [oauthVerifier, oauthToken, userId, logged, dispatch, user, jwt]);

  return (
    <div className="container-xl">
      <div className="page-header d-print-none">
        <div className="row align-items-center">
          <div className="col">
            <div className="page-pretitle">Cuenta</div>
            <h2 className="page-title">Connexiones</h2>
          </div>
        </div>
      </div>
      <div className="page-body">
        {connectionEnded ? (
          <div>
            Connexión con Garmin finalizada! A partir de ahora tus actividades
            se subirán automáticamente desde Garmin a XCLegends.
          </div>
        ) : (
          <Spinner size="30px" />
        )}
      </div>
    </div>
  );
};
