import { Link } from "react-router-dom";
import { useAppSelector } from "../hooks/useAppSelector";
import { selectAuthUser, logoutAsync, selectLogged } from "../store/authSlice";
import Modal from "../components/modals/Modal";
import { IconBook, IconHome } from "@tabler/icons";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { Nav, Navbar } from "react-bootstrap";
import { useState } from "react";
import { MainLogo } from "../components/logos/mainLogo";

export const Layout = (props: any) => {
  const user = useAppSelector(selectAuthUser);
  const logged = useAppSelector(selectLogged);
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useAppDispatch();

  if (window.location.href.match(/public/)) {
    return (
      <div className="zoom-wrapper">
        <Modal />
        <div>
          <div className="sticky-top">
            <Navbar collapseOnSelect bg="light" expand="md" sticky="top">
              <div className="container-xl">
                <div className="me-3">
                  <Link
                    to={logged ? "/timeline" : "/login"}
                    className="text-decoration-none"
                  >
                    <MainLogo width="100px" />
                  </Link>
                </div>
                <div className="navbar-nav flex-row order-md-last"></div>
              </div>
            </Navbar>
          </div>
          <div className="page-wrapper page-dark">{props.children}</div>
        </div>
      </div>
    );
  }

  if (!logged) {
    // No layout
    return (
      <div>
        <Modal />
        {props.children}
      </div>
    );
  }

  // Layout
  return (
    <div className="zoom-wrapper">
      <Modal />
      <div>
        <div className="sticky-top">
          <Navbar
            collapseOnSelect
            bg="light"
            expand="md"
            sticky="top"
            onSelect={() => {
              setCollapsed(false);
            }}
          >
            <div className="container-xl">
              <Navbar.Toggle
                className="navbar-toggler"
                type="button"
                aria-controls="responsive-navbar-nav"
                aria-expanded={collapsed}
                onClick={() => setCollapsed(!collapsed)}
              />
              <div className="me-3">
                <Link to="/timeline" className="text-decoration-none">
                  {" "}
                  <MainLogo width="100px" />
                </Link>
              </div>
              <div className="navbar-nav flex-row order-md-last">
                <div className="nav-item dropdown">
                  <div
                    className="nav-link d-flex lh-1 text-reset p-0 cursor-pointer"
                    data-bs-toggle="dropdown"
                    aria-label="Open user menu"
                  >
                    <div className="d-none d-md-block pe-3 text-end">
                      <div>{user.name}</div>
                      <div className="mt-1 small text-muted">Biker</div>
                    </div>
                    <span className="avatar avatar-sm avatar-rounded ml-1 text-bold">
                      {user.name[0]}
                    </span>
                  </div>
                  <div
                    role="menu"
                    className="dropdown-menu dropdown-menu-end dropdown-menu-arrow"
                  >
                    <Link
                      role="button"
                      to="/settings"
                      className="dropdown-item "
                    >
                      Mi cuenta
                    </Link>
                    <div
                      role="button"
                      onClick={() => {
                        dispatch(logoutAsync());
                      }}
                      className="dropdown-item"
                    >
                      Cerrar sesión
                    </div>
                  </div>
                </div>
              </div>
              <Navbar.Collapse id="navbar-menu">
                <Nav className="d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center">
                  <Nav.Item>
                    <Nav.Link as={Link} to="/timeline" href="/timeline">
                      <span className="nav-link-icon d-md-none d-lg-inline-block me-1">
                        <IconHome />
                      </span>
                      <span className="nav-link-title">Home</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link as={Link} to="/profile" href="/profile">
                      <span className="nav-link-icon d-md-none d-lg-inline-block me-1">
                        <IconBook />
                      </span>
                      <span className="nav-link-title">Mi perfil</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </div>
          </Navbar>
        </div>
        <div className="page-wrapper page-dark">{props.children}</div>
      </div>
    </div>
  );
};
