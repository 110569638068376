import { useState } from "react";
import {
  getCurrentYear,
  getArrayOfYears,
  getCurrentMonth,
  getArrayOfMonths,
  getCurrentDay,
  getArrayOfDays,
  deconstructDateTime,
} from "../../util/date";

interface DatePickerProps {
  id: string;
  date: string; //YYY-mm-dd
}

export const DatePicker = (props: DatePickerProps) => {
  const deconstructedDate = deconstructDateTime(props.date);

  const [year, setYear] = useState(
    deconstructedDate.year ? deconstructedDate.year : getCurrentYear() - 16
  );
  const [month, setMonth] = useState(
    deconstructedDate.month ? deconstructedDate.month : getCurrentMonth()
  );
  const [day, setDay] = useState(
    deconstructedDate.day ? deconstructedDate.day : getCurrentDay()
  );

  const listOfYears = getArrayOfYears();
  const listOfMonths = getArrayOfMonths();
  const listOfDays = getArrayOfDays(month, year);

  const stringDate =
    year +
    "-" +
    (month < 10 ? "0" : "") +
    month +
    "-" +
    (day < 10 ? "0" : "") +
    day;

  return (
    <div className="row g-2">
      <div className="col-3">
        <select
          data-testid="select-day"
          className="form-select"
          onChange={(e) => {
            setDay(+e.target.value);
          }}
          defaultValue={day}
        >
          {listOfDays.map((dayItem) => (
            <option key={`day${dayItem}`} value={dayItem}>
              {dayItem}
            </option>
          ))}
        </select>
      </div>
      <div className="col-5">
        <select
          data-testid="select-month"
          className="form-select"
          onChange={(e) => {
            setMonth(+e.target.value);
          }}
          defaultValue={month}
        >
          {listOfMonths.map((monthItem, index) => (
            <option key={`month${index}`} value={`${index + 1}`}>
              {monthItem}
            </option>
          ))}
        </select>
      </div>
      <div className="col-4">
        <select
          data-testid="select-year"
          className="form-select"
          onChange={(e) => {
            setYear(+e.target.value);
          }}
          defaultValue={year}
        >
          {listOfYears.map((yearItem) => (
            <option key={`year${yearItem}`} value={yearItem}>
              {yearItem}
            </option>
          ))}
        </select>
      </div>
      <input hidden readOnly type="text" id={props.id} value={stringDate} />
    </div>
  );
};
