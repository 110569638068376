import { IconCheck, IconTrash } from "@tabler/icons";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useEffect, useState } from "react";
import {
  getUser,
  updateUser,
  selectUserSliceLoading,
  selectCurrentUser,
} from "../../store/userSlice";
import { selectAuthUserId, deleteAccountAsync } from "../../store/authSlice";
import { Spinner } from "../../components/spinner/Spinner";
import useConfirmationModal from "../../hooks/useModalConfirmation";
import { MODALS } from "../../util/constants";
import { DatePicker } from "../../components/forms/DatePicker";
import { useAppDispatch } from "../../hooks/useAppDispatch";

export const Personal = () => {
  const dispatch = useAppDispatch();
  const [formModified, setFormModified] = useState(false);
  const user = useAppSelector(selectCurrentUser);
  const userId = useAppSelector(selectAuthUserId);
  const loading = useAppSelector(selectUserSliceLoading);
  const [saveConfirmation, setSaveConfirmation] = useState(false);

  const { openGenericModal } = useConfirmationModal();

  useEffect(() => {
    if (!user || user.uid !== userId) {
      dispatch(getUser(userId));
    }
  }, [user, userId, dispatch]);

  const onDeleteAccount = async () => {
    const isConfirmed = await openGenericModal(
      "Elimiar cuenta de usuario",
      "Atención. Tu sesión actual se cerrará. Todos tu datos, tu cuenta y actividades serán borrados permanentemente y la información no se podrá recuperar. ¿Estás seguro?",
      "ELIMINAR CUENTA",
      "Volver",
      MODALS.MODALS_TYPES.GENERIC_CONFIRMATION_WARNING
    );

    if (isConfirmed) {
      dispatch(deleteAccountAsync());
    }
  };

  useEffect(() => {
    if (saveConfirmation) {
      setTimeout(() => {
        setSaveConfirmation(false);
      }, 1000);
    }
  }, [saveConfirmation]);

  const onFormSubmit = (formEvent: any) => {
    formEvent.preventDefault();
    const target = formEvent.target;
    const userData = {
      name: target.name.value,
      gender: target.gender.value,
      weight: +target.weight.value,
      height: +target.height.value,
      units: target.units.value,
      birthDate: target.birthDate.value,
    };
    dispatch(updateUser({ userId, userData }));
    setFormModified(false);
    setSaveConfirmation(true);
  };

  if (!user || user.uid !== userId) {
    return (
      <div className="page page-center text-center mt-4">
        <div>
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className="container-xl">
      <div className="page-body">
        <div className="row row-cards">
          <div className="col-md-12">
            <h3>Datos de contacto</h3>
            <form
              onSubmit={(e) => onFormSubmit(e)}
              onChange={() => {
                setFormModified(true);
              }}
            >
              <fieldset disabled={loading}>
                <div className="form-group mb-3 row">
                  <label className="form-label col-3 col-form-label">
                    E-mail
                  </label>
                  <div className="col">
                    <input
                      type="email"
                      className="form-control disabled"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                      disabled
                      defaultValue={user.email}
                    />
                    <small className="form-hint">
                      Tu email es privado y nunca será compartido con nadie.
                    </small>
                  </div>
                </div>
                <div className="form-group mb-3 row">
                  <label className="form-label col-3 col-form-label">
                    Nombre
                  </label>
                  <div className="col">
                    <input
                      id="name"
                      name="name"
                      type="text"
                      className="form-control"
                      placeholder="Password"
                      defaultValue={user.name}
                    />
                  </div>
                </div>
                <h3>Datos del atleta</h3>
                <div className="form-group mb-3 row">
                  <label className="form-label col-3 col-form-label">
                    Fecha nacimiento
                  </label>
                  <div className="col">
                    <DatePicker id="birthDate" date={user.birthDate} />
                  </div>
                </div>
                <div className="form-group mb-3 row">
                  <label className="form-label col-3 col-form-label">
                    Sexo
                  </label>
                  <div className="col">
                    <select id="gender" name="gender" className="form-select">
                      <option value="male">Hombre</option>
                      <option value="female">Mujer</option>
                      <option value="other">Otro</option>
                    </select>
                  </div>
                </div>
                <div className="form-group mb-3 row">
                  <label className="form-label col-3 col-form-label">
                    Peso - Kg. (Ciclista + accesorios)
                  </label>
                  <div className="col">
                    <input
                      id="weight"
                      max={250}
                      min={40}
                      name="weight"
                      type="number"
                      className="form-control col-4"
                      aria-describedby="emailHelp"
                      placeholder="Ej: 80"
                      defaultValue={user.weight}
                    />
                    <small className="form-hint">
                      Tu peso vestido con tu equipación habitual ( ropa,
                      calzado, etc ).Lo usaremos para estimar mejor tus
                      estadísticas en cada actividad.
                    </small>
                  </div>
                </div>
                <div className="form-group mb-3 row">
                  <label className="form-label col-3 col-form-label">
                    Estatura (cm)
                  </label>
                  <div className="col">
                    <input
                      id="height"
                      name="height"
                      type="number"
                      className="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Ej: 175"
                      defaultValue={user.height}
                    />
                    <small className="form-hint">Tu estatura descalzo</small>
                  </div>
                </div>
                <h3>Otras configuraciones</h3>
                <div className="form-group mb-3 row">
                  <label className="form-label col-3 col-form-label">
                    Unidades
                  </label>
                  <div className="col">
                    <select
                      id="units"
                      name="units"
                      className="form-select"
                      defaultValue={user.units}
                      disabled
                    >
                      <option value="metric">Sistema métrico</option>
                    </select>
                  </div>
                </div>
                <div className="form-footer">
                  <div className="d-flex">
                    <div className="ms-auto d-flex">
                      {saveConfirmation && (
                        <div className="m-2 small text-teal bg-white">
                          {" "}
                          <IconCheck /> Guardado
                        </div>
                      )}
                      <input
                        type="submit"
                        className="btn btn-cyan"
                        value="Guardar"
                        disabled={!formModified}
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
          <div className="col-12 mt-5">
            <h3>Eliminar cuenta</h3>

            <p>
              Tu cuenta será eliminada, todas las actividades y tus datos serán
              borrados definitivamente. Esta acción no se puede deshacer.
            </p>
            <button onClick={onDeleteAccount} className="btn btn-md bg-red-lt">
              <IconTrash />
              <span className="ms-2">Borrar cuenta</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
