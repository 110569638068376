import { Link } from "react-router-dom";
import { format, fromUnixTime } from "date-fns";
import { timestampToTime } from "../../util/dataFormats";
import { IconClock, IconMountain, IconRoad, IconRoute } from "@tabler/icons";
import { SimpleMap } from "../maps/SimpleMap";

interface CompactActivityCardProps {
  id: string;
  date: number;
  name: string;
  totalDistance: number;
  totalClimb: number;
  totalTime: number;
  averageMovingSpeed: number;
  calories: number;
  normalizedPowerAverage: number;
  relativeEffort: number;
  segments: number;
  simplifiedTrack: any;
}

export const CompactActivityCard = (props: CompactActivityCardProps) => {
  return (
    <div className="col-12">
      <Link
        style={{ textDecoration: "none", color: "#232e3c" }}
        role="div"
        to={`/activity/${props.id}`}
      >
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-xs-12 col-md-1">
                <SimpleMap
                  mapId={props.id}
                  lineWidth={4}
                  track={props.simplifiedTrack || []}
                />
              </div>
              <div className="col-xs-12 col-md-3">
                <h3 className="mt-0 pt-0 mb-0">{props.name || ""}</h3>
                <p className="text-muted subheader">
                  {!!props.date
                    ? format(
                        fromUnixTime(props.date / 1000),
                        "dd/MM/yyyy hh:mm"
                      )
                    : ""}
                </p>
              </div>
              <div className="col-xs-12 col-md-8">
                <div className="row align-middle">
                  <div className="col-3 ">
                    <IconRoad /> {props.totalDistance} km
                  </div>
                  <div className="col-3">
                    <IconMountain /> {props.totalClimb} m
                  </div>
                  <div className="col-3">
                    <IconClock /> {timestampToTime(props.totalTime)}
                  </div>
                  <div className="col-3">
                    <IconRoute color="grey" alignmentBaseline="middle" />
                    {props.segments}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
