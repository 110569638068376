import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from './index';
import { ThunkExtraArguments } from './index';

interface confirmationModalState {
  cancelButtonText: string;
  description: string;
  isConfirmed: boolean;
  isDeclined: boolean;
  isOpened: boolean;
  modalProps: any;
  modalType: string;
  okButtonText: string;
  title: string;
}
export interface AppState {
  config: any;
  confirmationModal: confirmationModalState,
  loading: boolean;
  modalAction: any;
  modalActionText: string;
  modalContent: any;
  modalShow: boolean;
  modalTitle: string;
  modalType: string;
}

export const initialState: AppState = {
  config: {},
  confirmationModal: {
    cancelButtonText: '',
    description: '',
    isConfirmed: false,
    isDeclined: false,
    isOpened: false,
    modalProps: null,
    modalType: '',
    okButtonText: '',
    title: ''
  },
  loading: false,
  modalAction: null,
  modalActionText: '',
  modalContent: null,
  modalShow: false,
  modalTitle: '',
  modalType: ''
};

export const confirmationModalThunkActions = {
  openGenericModalAction: createAsyncThunk<boolean, any, {
    extra: ThunkExtraArguments
  }>(
    'confirmationModal',
    async ( modalContent, { extra, dispatch } ) => {
      const store = extra.store;
      dispatch( openGenericModal( modalContent ) );
      return new Promise<boolean>( resolve => {
        const unsubscribe = store.subscribe( () => {
          const state: RootState = store.getState();
          if ( state.app.confirmationModal.isConfirmed ) {
            unsubscribe();
            resolve( true );
          }
          if ( state.app.confirmationModal.isDeclined ) {
            unsubscribe();
            resolve( false );
          }
        } )
      } );
    }
  ),
  openModalComponentAction: createAsyncThunk<boolean, any, {
    extra: ThunkExtraArguments
  }>(
    'modalComponent',
    async ( modalContent, { extra, dispatch } ) => {
      const store = extra.store;
      dispatch( openModalComponent( modalContent ) );
      return new Promise<boolean>( resolve => {
        const unsubscribe = store.subscribe( () => {
          const state: RootState = store.getState();
          if ( state.app.confirmationModal.isConfirmed ) {
            unsubscribe();
            resolve( true );
          }
          if ( state.app.confirmationModal.isDeclined ) {
            unsubscribe();
            resolve( false );
          }
        } )
      } );
    }
  )
}

export const getConfig = createAsyncThunk(
  'app/config',
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}/api/app/config`,
      { withCredentials: true }
    );
    return response.data;
  }
);

export const appSlice = createSlice( {
  name: 'app',
  initialState,
  reducers: {
    closeModal: ( state ) => {
      state.modalAction = null;
      state.modalShow = false;
    },
    confirm: state => {
      state.confirmationModal.isConfirmed = true;
      state.confirmationModal.isOpened = false;
    },
    decline: state => {
      state.confirmationModal.isDeclined = true;
      state.confirmationModal.isOpened = false;
    },
    openGenericModal: ( state, action ) => {
      state.confirmationModal.cancelButtonText = action.payload.cancelButtonText;
      state.confirmationModal.description = action.payload.description;
      state.confirmationModal.isConfirmed = false;
      state.confirmationModal.isDeclined = false;
      state.confirmationModal.isOpened = true;
      state.confirmationModal.modalType = action.payload.modalType;
      state.confirmationModal.okButtonText = action.payload.okButtonText;
      state.confirmationModal.title = action.payload.title;
    },
    openModalComponent: ( state, action ) => {
      state.confirmationModal.isConfirmed = false;
      state.confirmationModal.isDeclined = false;
      state.confirmationModal.isOpened = true;
      state.confirmationModal.modalProps = action.payload.modalProps;
      state.confirmationModal.modalType = action.payload.componentName;

    }
  }, extraReducers: ( builder ) => {
    builder
      .addCase( getConfig.pending, ( state ) => {
        state.loading = true;
      } )
      .addCase( getConfig.fulfilled, ( state, action ) => {
        state.loading = false;
        state.config = action.payload;
      } )
  }
} );

export const { closeModal, openGenericModal, openModalComponent, confirm, decline } = appSlice.actions;

export const selectConfig = ( state: RootState ) => state.app.config;
export const selectLoading = ( state: RootState ) => state.app.loading;
export const selectModalState = ( state: RootState ) => state.app.modalShow;
export const selectModalTitle = ( state: RootState ) => state.app.confirmationModal.title;
export const selectModaType = ( state: RootState ) => state.app.modalType;

export default appSlice.reducer;
