import { Field, Form, Formik } from "formik";
import {
  getActivity,
  selectCurrentActivity,
  updateActivity,
} from "../../store/activitiesSlice";
import { IconPlus } from "@tabler/icons";
import { OpenMap } from "../../components/maps/OpenMap";
import { Spinner } from "../../components/spinner/Spinner";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useEffect, useMemo, useState, useCallback } from "react";
import { createSegment } from "../../store/segmentsSlice";
import useConfirmationModal from "../../hooks/useModalConfirmation";
import { COLORS, MODALS } from "../../util/constants";
import { RouteComponentProps } from "react-router-dom";
import FormRange from "react-bootstrap/FormRange";
import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
  name: Yup.string().max(50, "Too Long!").required("Campo obligatorio"),
});

export const CreateSegment = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const [loading, setLoading] = useState(false);
  const [fitMapToContent, setFitMapToContent] = useState(true);
  const activity: any = useAppSelector(selectCurrentActivity);
  const { openGenericModal } = useConfirmationModal();
  const activityId = props.match.params.id;

  const track = useMemo(() => {
    const a = activity?.track?.full
      .filter((point: any, i: any) => i >= start && i <= end)
      .map((point: any) => [point.lt, point.ln]);
    return a;
  }, [start, end, activity]);

  const startChange = useCallback(
    (startValue: number) => {
      if (startValue < end) {
        setStart(startValue);
      }
    },
    [end]
  );

  const endChange = useCallback(
    (endValue: number) => {
      if (endValue > start) {
        setEnd(endValue);
      }
    },
    [start]
  );

  const saveSegment = async (values: any) => {
    setLoading(true);
    await dispatch(
      createSegment({
        name: values.name,
        track,
      })
    );
    const isConfirmed = await openGenericModal(
      "Recalcular actividad",
      "Quieres recalcular la actividad de origen y añadir este segmento? " +
        "Esto añadirá tu segmento y otros nuevos si los hay. Si tu peso o tu bicicleta " +
        "ha cambiado recientemente las estadísticas podrían cambiar.",
      "Si",
      "No",
      MODALS.MODALS_TYPES.GENERIC_CONFIRMATION
    );
    if (isConfirmed) {
      await dispatch(updateActivity({ activityId }));
    }
    props.history.push(`/activity/${activityId}`);
  };

  useEffect(() => {
    if (activity?.track?.full) {
      setEnd(activity?.track?.full?.length);
    }
  }, [activity]);

  useEffect(() => {
    dispatch(getActivity(activityId));
  }, [activityId, dispatch]);

  if (!activity || activity._id !== activityId) {
    return (
      <div className="page page-center text-center">
        <div>
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="container-xl">
        <div className="page-header d-print-none">
          <div className="row align-items-center">
            <div className="col-9">
              <div className="page-pretitle">Segmentos</div>
              <h2 className="page-title">Crear segmento</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-deck row-cards">
            <div className="col-0">
              <div className="card">
                <div className="row">
                  <div className="col-12" style={{ minHeight: "400px" }}>
                    <OpenMap
                      coords={track || []}
                      color={COLORS.MAP_LINE}
                      fitMapToContent={fitMapToContent}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex justify-content-end align-items-baseline ">
              <input
                className="float-end"
                type="checkbox"
                disabled={loading}
                key="fitMapToContent"
                checked={fitMapToContent}
                onChange={() => {
                  setFitMapToContent(!fitMapToContent);
                }}
              />
              <label className=" ms-1 form-label fw-light float-end">
                Centrar mapa automáticamente
              </label>
            </div>
            <div className="col-0">
              <div className="card">
                <div className="card-body">
                  <Formik
                    initialValues={{
                      name: "",
                      type: 1,
                      tireType: 1,
                      terrainType: 1,
                      condition: 1,
                      initialKms: 0,
                      weight: 0,
                      ...props,
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={saveSegment}
                  >
                    {({ errors }) => (
                      <Form>
                        <div className="">
                          <h3 className="card-title">Tiempos</h3>
                        </div>

                        <div className="mb-4">
                          <label className="form-label">Inicio</label>
                          <FormRange
                            disabled={loading}
                            key="start"
                            value={start}
                            min={0}
                            max={activity.track.full.length}
                            onChange={(e) => {
                              startChange(+e.target.value);
                            }}
                          />
                        </div>
                        <div className="mb-4">
                          <label className="form-label">Fin</label>
                          <FormRange
                            disabled={loading}
                            key="end"
                            value={end}
                            min={0}
                            max={activity.track.full.length}
                            onChange={(e) => {
                              endChange(+e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-3 col-12">
                          <label className="form-label">Nombre</label>
                          <Field
                            disabled={loading}
                            id="name"
                            name="name"
                            type="text"
                            className={`form-control ${
                              errors.name && "is-invalid"
                            }`}
                            autoComplete="off"
                            placeholder="Nombre"
                          />
                          <div className="invalid-feedback">
                            {"" + errors.name}
                          </div>
                        </div>
                        <div className="float-end">
                          {loading ? (
                            <Spinner size="25px" />
                          ) : (
                            <button
                              disabled={loading}
                              id="test2"
                              type="submit"
                              className={`btn btn-cyan `}
                            >
                              <IconPlus /> Añadir
                            </button>
                          )}
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
