export const MODALS_CONTENT = {

    DELETE_ACTIVITY: [
        'Eliminar actividad?',
        'Esta acción no se puede deshacer y afectará a tus estadísticas y objectivos.',
        'Eliminar actividad',
        'Cancelar'
    ],
    RECALCULATE_STATS: [
        'Recalcular estadísticas',
        'Atención! Las estadísitcas de esta actividad se calcularan en base a tu peso y configuración de bicicleta actual y podrian cambiar. '
        + 'Además se calcularán nuevos segmentos si los hay. Estás seguro de recalcular?',
        'Recalcular',
        'Cancelar'
    ]

};

