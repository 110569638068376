import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "./index";

export interface UserState {
  bikes: any;
  loading: boolean;
  user: any;
}

export const initialState: UserState = {
  bikes: [],
  loading: false,
  user: null,
};

export const removeGarminAccount = createAsyncThunk(
  "user/disconnectGarmin",
  async (_, { getState, dispatch }) => {
    const state: any = getState();
    const jwt = state.auth.jwt;
    await axios.delete(
      `${process.env.REACT_APP_API_HOST}/api/connections/garmin/disconnect`,
      { withCredentials: true, headers: { "x-token": jwt } }
    );
  }
);

export const getUser = createAsyncThunk(
  "user/getSingle",
  async (userId: any, { getState }) => {
    const state: any = getState();
    const jwt = state.auth.jwt;
    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}/api/users/${userId}`,
      { withCredentials: true, headers: { "x-token": jwt } }
    );
    return response.data;
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (data: any, { getState }) => {
    const state: any = getState();
    const jwt = state.auth.jwt;
    const { userId, userData } = data;
    const response = await axios.put(
      `${process.env.REACT_APP_API_HOST}/api/users/${userId}`,
      userData,
      { withCredentials: true, headers: { "x-token": jwt } }
    );
    return response.data;
  }
);

export const createBike = createAsyncThunk(
  "user/createBike",
  async (data: any, { getState }) => {
    const state: any = getState();
    const jwt = state.auth.jwt;
    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}/api/bikes`,
      { ...data },
      { withCredentials: true, headers: { "x-token": jwt } }
    );
    return response.data;
  }
);

export const updateBike = createAsyncThunk(
  "user/updateBike",
  async (data: any, { getState }) => {
    const { _id, ...bikeData } = data;
    const state: any = getState();
    const jwt = state.auth.jwt;
    const response = await axios.put(
      `${process.env.REACT_APP_API_HOST}/api/bikes/${_id}`,
      { ...bikeData },
      { withCredentials: true, headers: { "x-token": jwt } }
    );
    return response.data;
  }
);

export const deleteBike = createAsyncThunk(
  "user/deleteBike",
  async (id: string, { getState }) => {
    const state: any = getState();
    const jwt = state.auth.jwt;
    const response = await axios.delete(
      `${process.env.REACT_APP_API_HOST}/api/bikes/${id}`,
      { withCredentials: true, headers: { "x-token": jwt } }
    );
    return response.data;
  }
);

export const getBikes = createAsyncThunk(
  "user/getBikes",
  async (params: any, { getState }) => {
    const state: any = getState();
    const jwt = state.auth.jwt;
    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}/api/bikes`,
      { withCredentials: true, headers: { "x-token": jwt } }
    );
    return response.data;
  }
);

export const deleteUser = createAsyncThunk(
  "user/delete",
  async (userId: any, { getState }) => {
    const state: any = getState();
    const jwt = state.auth.jwt;
    const response = await axios.delete(
      `${process.env.REACT_APP_API_HOST}/api/users/${userId}`,
      { withCredentials: true, headers: { "x-token": jwt } }
    );
    return response.data;
  }
);

export const getUserStats = createAsyncThunk(
  "user/stats",
  async (params: { days: number; userId: string }, { getState }) => {
    const state: any = getState();
    const jwt = state.auth.jwt;
    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}/api/users/${params.userId}/stats?days=${params.days}`,
      { withCredentials: true, headers: { "x-token": jwt } }
    );
    return response.data;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUser.fulfilled, (state, action: any) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(updateUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(getBikes.fulfilled, (state, action) => {
        state.bikes = action.payload;
      });
  },
});

export const selectCurrentUser = (state: RootState) => state.user.user;
export const selectCurrentUserConfig = (state: RootState) =>
  state.user.user?.config;
export const selectBikes = (state: RootState) => state.user.bikes;
export const selectUserSliceLoading = (state: RootState) => state.user.loading;

export default userSlice.reducer;
