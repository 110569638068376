import { Equipment } from "./Equipment";
import { Connections } from "./Connections";
import { Privacy } from "./Privacy";
import { Personal } from "./Personal";
import { Route, RouteComponentProps } from "react-router-dom";
import { IconEye, IconGridDots, IconSocial, IconUser } from "@tabler/icons";

export const Settings = (props: RouteComponentProps) => {
  const isActive = (route: string, exact = false) => {
    if (exact) {
      return props.location.pathname === route;
    }
    return props.location.pathname.indexOf(route) !== -1;
  };

  return (
    <div className="container-xl">
      <div className="page-header d-print-none">
        <div className="row align-items-center">
          <div className="page-pretitle">Cuenta</div>
          <h2 className="page-title">Mi cuenta</h2>
        </div>
      </div>
      <div className="page-body">
        <div className="row row-cards">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <ul
                  className="nav nav-tabs nav-fill card-header-tabs"
                  data-bs-toggle="tabs"
                >
                  <li className="nav-item">
                    <div
                      className={`nav-link cursor-pointer ${
                        (isActive("/settings/personal") ||
                          isActive("/settings", true)) &&
                        "active"
                      } `}
                      onClick={() => props.history.push("/settings/personal")}
                    >
                      <IconUser />
                      <span className="d-none d-md-block mx-1">Personal</span>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      className={`nav-link cursor-pointer ${
                        isActive("/settings/equipment") && "active"
                      }`}
                      onClick={() => props.history.push("/settings/equipment")}
                    >
                      <IconGridDots />
                      <span className="d-none d-md-block  mx-1">
                        Equipamiento
                      </span>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      className={`nav-link cursor-pointer ${
                        isActive("/settings/connections") && "active"
                      }`}
                      onClick={() =>
                        props.history.push("/settings/connections")
                      }
                    >
                      <IconSocial />
                      <span className="d-none d-md-block  mx-1">
                        Conexiones
                      </span>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      className={`nav-link cursor-pointer ${
                        isActive("/settings/privacy") && "active"
                      }`}
                      onClick={() => props.history.push("/settings/privacy")}
                    >
                      <IconEye />
                      <span className="d-none d-md-block  mx-1">
                        Privacidad
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <div className="tab-content">
                  <Route>
                    <Route
                      exact
                      path={["/settings/", "/settings/personal"]}
                      component={Personal}
                    />
                    <Route
                      exact
                      path="/settings/equipment"
                      component={Equipment}
                    />
                    <Route
                      exact
                      path="/settings/connections/"
                      component={Connections}
                    />
                    <Route
                      exact
                      path="/settings/privacy/"
                      component={Privacy}
                    />
                  </Route>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
