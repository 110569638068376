import Chart from "react-apexcharts";

interface LineChartProps {
  categories: [];
  series: LineChartSerie[];
  tooltip: boolean;
  height: string;
  width: string;
  curve?: "smooth" | "straight" | "stepline";
  dataLabels?: boolean;
  onMouseOver: (dataPointIndex: number) => unknown;
  onMouseOut: () => void;
}

interface LineChartSerie {
  id: string;
  name: string;
  data: number[];
  color: string;
}

export const LineChart = (props: LineChartProps) => {
  const yaxis = props.series.map((serie) => ({
    seriesName: serie.name,
    show: false,
  }));

  const colors = props.series.map((serie) => serie.color);

  return (
    <Chart
      options={{
        chart: {
          id: "line",
          toolbar: { show: false },
          parentHeightOffset: 0,
          events: {
            mouseMove: (_, __, context) =>
              props.onMouseOver(context.dataPointIndex),
            mouseLeave: () => props.onMouseOut(),
          },
        },
        xaxis: {
          categories: props.categories,
          labels: { show: false },
          axisBorder: { show: false },
          axisTicks: { show: false },
          tooltip: { enabled: false },
        },
        fill: {
          colors: colors,
        },
        yaxis: yaxis,
        dataLabels: {
          enabled: !!props.dataLabels,
        },
        legend: {
          show: false,
        },
        stroke: {
          width: 2,
          curve: props.curve || "smooth",
        },
        grid: {
          show: false,
          padding: { left: 0, right: 0, top: 0, bottom: -30 },
        },
        tooltip: {
          enabled: props.tooltip,
        },
      }}
      series={props.series}
      type="line"
      width={props.width}
      height={props.height}
    />
  );
};
