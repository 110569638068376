import { useEffect } from "react";
import { useAppDispatch } from "./useAppDispatch";
import { getActivities, resetList, selectList } from "../store/activitiesSlice";
import { useAppSelector } from "./useAppSelector";

export const useActivitiesList = () => {
  const dispatch = useAppDispatch();
  const listTemp: any = useAppSelector(selectList);

  useEffect(() => {
    dispatch(getActivities());
    return () => {
      dispatch(resetList());
    };
  }, [dispatch]);
  return { listTemp };
};
