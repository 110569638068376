import { useAppSelector } from "../../hooks/useAppSelector";
import {
  selectList,
  getActivities,
  selectPagination,
  selectActivitiesLoading,
  resetList,
  selectActivitiesListTotal,
} from "../../store/activitiesSlice";
import { useEffect, useRef, useCallback } from "react";
import { SimpleMap } from "../../components/maps/SimpleMap";
import { Link } from "react-router-dom";
import { Spinner } from "../../components/spinner/Spinner";
import { format, fromUnixTime } from "date-fns";
import { timestampToTime } from "../../util/dataFormats";
import { MODALS } from "../../util/constants";
import useConfirmationModal from "../../hooks/useModalConfirmation";
import {
  IconBolt,
  IconFlame,
  IconPlus,
  IconInfoCircle,
  IconRoute,
} from "@tabler/icons";
import { useAppDispatch } from "../../hooks/useAppDispatch";

export const Timeline = () => {
  const dispatch = useAppDispatch();
  const listTemp: any = useAppSelector(selectList);
  const pagination: any = useAppSelector(selectPagination);
  const loading = useAppSelector(selectActivitiesLoading);
  const totalActivities = useAppSelector(selectActivitiesListTotal);
  const list = useRef<any[]>([]);
  const { openModalWithComponent } = useConfirmationModal();

  const activitiesIds = list.current.map((activity) => activity.id);
  const newActivities: any[] = [];
  listTemp.forEach((element: any) => {
    if (!activitiesIds.includes(element.id)) {
      newActivities.push(element);
    }
  });
  list.current = [...list.current, ...newActivities].sort(
    (a: any, b: any) => +b.date - +a.date
  );

  const handleScroll = useCallback(() => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;
    if (bottom && listTemp.length > 0 && !loading) {
      dispatch(
        getActivities({
          ...pagination,
          start: pagination.start + pagination.limit,
        })
      );
    }
  }, [dispatch, listTemp, pagination, loading]);

  const setPaginationEvent = useCallback(() => {
    window.addEventListener("scroll", handleScroll, true);
  }, [handleScroll]);

  const removePaginationEvent = useCallback(() => {
    window.removeEventListener("scroll", handleScroll, true);
  }, [handleScroll]);

  useEffect(() => {
    dispatch(getActivities());
    return () => {
      dispatch(resetList());
    };
  }, [dispatch]);

  useEffect(() => {
    setPaginationEvent();
    return () => {
      removePaginationEvent();
    };
  }, [listTemp, setPaginationEvent, removePaginationEvent]);

  if (!list) {
    return (
      <div className="page page-center text-center">
        <div>
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="container-xl">
        <div className="page-header d-print-none">
          <div className="row align-items-center">
            <div className="col">
              <div className="page-pretitle">General</div>
              <h2 className="page-title">Resumen de actividades</h2>
            </div>
            <div className="col-auto ms-auto d-print-none">
              <div className="btn-list">
                <button
                  onClick={() => {
                    openModalWithComponent(MODALS.MODALS_TYPES.ADD_ACTIVITY);
                  }}
                  className="btn btn-cyan"
                >
                  <IconPlus /> actividad
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-deck row-cards">
            {list.current.map((activity: any) => (
              <Link
                key={activity.id}
                className="col-12"
                style={{ textDecoration: "none", color: "#232e3c" }}
                role="div"
                to={`/activity/${activity.id}`}
              >
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-8 col-12">
                        <div className="row">
                          <div className="col-12 text-left">
                            <p className="text-muted small mb-0 lh-base">
                              {" "}
                              <span className="badge bg-cyan-lt me-1">
                                Entreno
                              </span>{" "}
                              {!!activity.date
                                ? format(
                                    fromUnixTime(activity.date / 1000),
                                    "dd/MM/yyyy"
                                  )
                                : ""}
                            </p>
                            <h2 className=" mt-3 mb-3">
                              {activity.name || ""}
                            </h2>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="col-12 d-flex">
                            <div className="col-sm-3 col-4">
                              <div className="align-items-center ">
                                <div className="subheader">Distancia</div>
                              </div>
                              <div className="h3 mb-1">
                                {activity.stats.totalDistance} km
                              </div>
                            </div>
                            <div className="col-sm-3 col-4">
                              <div className="align-items-center ">
                                <div className="subheader">Desnivel</div>
                              </div>
                              <div className="h3 mb-1">
                                {activity.stats.totalClimb} m
                              </div>
                            </div>
                            <div className="col-sm-3 col-4">
                              <div className="align-items-center">
                                <div className="subheader">Tiempo total</div>
                              </div>
                              <div className="h3 mb-1">
                                {timestampToTime(activity.stats.totalTime)}
                              </div>
                            </div>
                            <div className="col-sm-3 d-sm-block d-none">
                              <div className="align-items-center">
                                <div className="subheader">Velocidad Ø</div>
                              </div>
                              <div className="h3 mb-1">
                                {activity.stats.averageMovingSpeed} km/h
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 mt-3 row ">
                          {!!activity.stats.calories && (
                            <div className="col-3 text-muted">
                              {activity.stats.calories} KCal
                            </div>
                          )}
                          {!!activity.stats.normalizedPowerAverage && (
                            <div className="col-3 text-muted">
                              {activity.stats.normalizedPowerAverage} W{" "}
                              <IconBolt
                                size="5"
                                color="red" // set `stroke` color
                                stroke={5} // set `stroke-width`
                                alignmentBaseline="middle"
                              />
                            </div>
                          )}
                          {activity.stats.relativeEffort && (
                            <div className="col-3 text-muted">
                              {activity.stats.relativeEffort} / 10{" "}
                              <IconFlame
                                size="5"
                                color={
                                  activity.stats.relativeEffort > 7
                                    ? "red"
                                    : "orange"
                                }
                                stroke={5} // set `stroke-width`
                                alignmentBaseline="middle"
                              />
                            </div>
                          )}
                          <div className="col-3 text-muted">
                            {activity.segments}{" "}
                            <IconRoute
                              color="grey"
                              alignmentBaseline="middle"
                            />
                          </div>
                        </div>
                      </div>
                      {
                        <div className="col-md-4 col-12">
                          <SimpleMap
                            mapId={activity.id}
                            lineWidth={1}
                            track={activity.track.simplified || []}
                          />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </Link>
            ))}
            {loading && (
              <div className="d-flex align-items-center justify-content-center">
                <Spinner />
              </div>
            )}
            {listTemp.length === 0 && list.current.length > 0 && (
              <div className="text-center text-muted">
                <IconInfoCircle /> No hay más actividades.
              </div>
            )}
            {!loading && totalActivities === 0 && list.current.length === 0 && (
              <div className="text-center mt-5 pt-5 text-muted h4">
                <IconInfoCircle /> No hay actividades. <br />
                Añade tu primera actividad{" "}
                <u
                  className="link cursor-pointer text-info"
                  role="link"
                  onClick={() => {
                    openModalWithComponent(MODALS.MODALS_TYPES.ADD_ACTIVITY);
                  }}
                >
                  aquí
                </u>
                .
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
