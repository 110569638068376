import { CompactActivityCard } from "../../components/activity/CompactActivityCard";
import PeriodStats from "../../components/stats/PeriodStats";
import { useActivitiesList } from "../../hooks/useActivitiesList";
import { useAppSelector } from "../../hooks/useAppSelector";
import { selectAuthUser } from "../../store/authSlice";

export const Profile = () => {
  const { listTemp } = useActivitiesList();
  const user = useAppSelector(selectAuthUser);

  return (
    <>
      <div className="container-xl">
        <div className="page-header d-print-none">
          <div className="row align-items-center">
            <div className="col">
              <div className="page-pretitle">Atleta</div>
              <h2 className="page-title">{user.name}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="container-xl">
          <div className="col-12">
            <h3>Evolución</h3>
            <div className="row">
              <div className="col-12">
                <PeriodStats days={30} compactMode />
              </div>
            </div>
          </div>
        </div>
        <div className="container-xl">
          <h3>Últimas actividades</h3>
          {listTemp
            .filter((_: unknown, index: number) => index < 5)
            .map((activity: any) => (
              <CompactActivityCard
                key={activity.id}
                id={activity.id}
                name={activity.name}
                date={activity.date}
                totalDistance={activity.stats.totalDistance}
                totalClimb={activity.stats.totalClimb}
                totalTime={activity.stats.totalTime}
                averageMovingSpeed={activity.stats.averageMovingSpeed}
                calories={activity.stats.calories}
                normalizedPowerAverage={activity.stats.normalizedPowerAverage}
                relativeEffort={activity.stats.relativeEffort}
                segments={activity.segments}
                simplifiedTrack={activity.track.simplified}
              />
            ))}
        </div>
      </div>
    </>
  );
};
