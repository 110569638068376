import {
  getActivity,
  selectCurrentActivity,
  deleteActivity,
  getActivities,
  updateActivity,
} from "../../store/activitiesSlice";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useEffect, useState, useMemo, useCallback } from "react";
import { Spinner } from "../../components/spinner/Spinner";
import { timestampToDate, timestampToTime } from "../../util/dataFormats";
import { LineChart } from "../../components/charts/LineChart";
import {
  IconFlame,
  IconSettings,
  IconInfoCircle,
  IconTemperature,
  IconHeart,
  IconSpiral,
  IconMountain,
  IconShare,
} from "@tabler/icons";
import useConfirmationModal from "../../hooks/useModalConfirmation";
import { COLORS, METRICS, MODALS, VISIBILITY } from "../../util/constants";
import { selectAuthUser } from "../../store/authSlice";
import { OpenMap } from "../../components/maps/OpenMap";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { BarChart } from "../../components/charts/BarChart";
import { RouteComponentProps } from "react-router-dom";
import { SelectableButton } from "../../components/forms/SelectableButton";
import { MODALS_CONTENT } from "../../util/modals";

export const ActivityDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const activity: any = useAppSelector(selectCurrentActivity);
  const user: any = useAppSelector(selectAuthUser);
  const { openGenericModal, openModalWithComponent } = useConfirmationModal();
  const activityId = props.match.params.id;
  const isCurrentUserActivity = activity && activity.user._id === user?.uid;
  const [coords, setCoords] = useState([]);
  const [points, setPoints] = useState<any[]>([]);
  const [selectedDetails, setSelectedDetails] = useState<string[]>([
    METRICS.CADENCE,
    METRICS.ELEVATION,
    METRICS.HR,
    METRICS.TEMPERATURE,
  ]);

  useEffect(() => {
    dispatch(getActivity(activityId)).then((response: any) => {
      if (response.error && !response.payload) {
        props.history.push("/");
      }
    });
  }, [activityId, dispatch, props.history]);

  useEffect(() => {
    if (!!activity && activityId === activity._id) {
      setCoords(
        activity?.track?.full.map((point: any) => [point.lt, point.ln]) || []
      );
    }
  }, [activity?.track, activityId, activity]);

  const updateDetails = (id: string, value: boolean) => {
    if (value) {
      if (selectedDetails.indexOf(id) < 0) {
        setSelectedDetails([...selectedDetails, id]);
      }
    } else {
      setSelectedDetails(selectedDetails.filter((detail) => detail !== id));
    }
  };

  const onLineChartMouseOver = useCallback(
    (dataPointIndex: number) => {
      const index = dataPointIndex;
      if (index > 0) {
        const point = activity?.track?.simplified[index];
        setPoints([{ center: [point.lt, point.ln] }]);
      }
    },
    [activity?.track?.simplified]
  );

  const onEditActivity = async () => {
    openModalWithComponent(MODALS.MODALS_TYPES.EDIT_ACTIVITY, {
      activity: {
        _id: activity._id,
        name: activity.name,
        bikeId: activity.bike ? activity.bike._id : null,
      },
    });
  };

  const onDeleteActivity = async () => {
    const isConfirmed = await openGenericModal(
      ...MODALS_CONTENT.DELETE_ACTIVITY,
      MODALS.MODALS_TYPES.GENERIC_CONFIRMATION_WARNING
    );
    if (isConfirmed) {
      await dispatch(deleteActivity(activityId));
      await dispatch(getActivities());
      props.history.push("/timeline");
    }
  };

  const copyCurrentURLToClipboard = () => {
    navigator.clipboard.writeText(
      window.location.href.replace("activity", "public/activity")
    );
  };

  const onCreateSegment = async () => {
    props.history.push(`/segment/${activityId}`);
  };

  const onRecalculateStats = async () => {
    const isConfirmed = await openGenericModal(
      ...MODALS_CONTENT.RECALCULATE_STATS,
      MODALS.MODALS_TYPES.GENERIC_CONFIRMATION_WARNING
    );
    if (isConfirmed) {
      await dispatch(updateActivity({ activityId }));
    }
  };

  const lineChartMemo = useMemo(() => {
    const timeArray = activity
      ? activity.track.simplified.map((element: { t: number }) =>
          timestampToTime(element.t)
        )
      : [];

    const series = [
      {
        id: METRICS.HR,
        name: "Frecuencia cardiaca",
        data: activity
          ? activity.track.simplified.map((t: any) => (!!t.hr ? t.hr : 0))
          : [],
        color: COLORS.METRICS.HR,
      },
      {
        id: METRICS.CADENCE,
        name: "Cadencia",
        data: activity
          ? activity.track.simplified.map((t: any) => (!!t.cad ? t.cad : 0))
          : [],
        color: COLORS.METRICS.CADENCE,
      },
      {
        id: METRICS.TEMPERATURE,
        name: "Temperatura",
        data: activity
          ? activity.track.simplified.map((t: any) => (!!t.tp ? t.tp : 0))
          : [],
        color: COLORS.METRICS.TEMPERATURE,
      },
      {
        id: METRICS.ELEVATION,
        name: "Elevación",
        data: activity
          ? activity.track.simplified.map((t: any) =>
              !!t.e ? Math.round(t.e) : 0
            )
          : [],
        color: COLORS.METRICS.ELEVATION,
      },
    ];

    return (
      <LineChart
        series={series.filter(
          (serie) => selectedDetails.indexOf(serie.id) >= 0
        )}
        categories={timeArray}
        tooltip={true}
        width="100%"
        height="200px"
        onMouseOver={onLineChartMouseOver}
        onMouseOut={() => setPoints([])}
      />
    );
  }, [activity, selectedDetails, onLineChartMouseOver]);

  if (!activity || activity._id !== activityId) {
    return (
      <div className="page page-center text-center mt-4">
        <div>
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="container-xl">
        <div className="page-header d-print-none">
          <div className="row align-items-center">
            <div className="col-9">
              <div className="page-pretitle">Análisis</div>
              <h2 className="page-title">Actividad</h2>
            </div>
            {isCurrentUserActivity && (
              <div className="col-3 d-flex  justify-content-end">
                {user.config.privacy.activitiesVisibility ===
                  VISIBILITY.PUBLIC && (
                  <button
                    onClick={copyCurrentURLToClipboard}
                    className="btn btn-default me-2"
                    aria-label="Button"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Tooltip on bottom"
                  >
                    <IconShare /> Copiar URL
                  </button>
                )}
                <div className="dropdown ">
                  <div
                    role="button"
                    className=" btn btn-cyan dropdown-toggle ms-auto"
                    data-bs-toggle="dropdown"
                  >
                    <IconSettings />
                  </div>
                  <div className="dropdown-menu dropdown-menu-end">
                    <span className="dropdown-header">Opciones</span>
                    <div
                      className="dropdown-item "
                      role="button"
                      onClick={onCreateSegment}
                    >
                      Crear segmento
                    </div>
                    <div
                      className="dropdown-item "
                      role="button"
                      onClick={onRecalculateStats}
                    >
                      Recalcular estadísticas
                    </div>
                    <div
                      className="dropdown-item "
                      role="button"
                      onClick={onEditActivity}
                    >
                      Editar actividad
                    </div>
                    <div
                      className="dropdown-item text-orange"
                      role="button"
                      onClick={onDeleteActivity}
                    >
                      Eliminar actividad
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-deck row-cards">
            {isCurrentUserActivity &&
              !!activity.stats.totalTime &&
              !activity.stats.normalizedPowerAverage && (
                <div className="col-0">
                  <div className="card">
                    <div className="alert alert-info mb-0" role="alert">
                      <div className="d-flex">
                        <div className="me-3">
                          <IconInfoCircle className="text-blue" />
                        </div>
                        <div>
                          <h4 className="alert-title">
                            Mejora el análisis de tus actividades
                          </h4>
                          <div className="text-muted">
                            Añade tu peso y tu bicicleta a esta actividad para
                            obtener datos adicionales de tu rendimiento.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            <div className="col-0">
              <div className="card">
                <div className="card-body">
                  <div className="d-md-flex">
                    <div className="col-12 col-md-8 text-left d-md-flex align-items-baseline">
                      <h1 className="card-title d-inline me-2">
                        {activity.name || ""}
                      </h1>
                      <span className="badge bg-cyan-lt me-1 d-md-inline">
                        Entreno
                      </span>
                    </div>
                    <div className="col-12 col-md-4 text-md-end">
                      <p className="text-muted small lh-base">
                        {activity.bike && activity.bike.name + " - "}{" "}
                        {timestampToDate(activity.date)}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 col-sm-3">
                      <div className="align-items-center ">
                        <div className="subheader ">Distancia</div>
                      </div>
                      <div className="h2 mb-3">
                        {activity.stats.totalDistance} km
                      </div>
                    </div>
                    <div className="col-6 col-sm-3">
                      <div className="align-items-center ">
                        <div className="subheader">Desnivel</div>
                      </div>
                      <div className="h2 mb-3">
                        {activity.stats.totalClimb} m
                      </div>
                    </div>
                    {!!activity.stats.movingTime && (
                      <div className="col-6 col-sm-3">
                        <div className="align-items-center">
                          <div className="subheader">Tiempo en movimiento</div>
                        </div>
                        <div className="h2 mb-3">
                          {timestampToTime(activity.stats.movingTime)}
                        </div>
                      </div>
                    )}
                    {!!activity.stats.averageMovingSpeed && (
                      <div className="col-6 col-sm-3">
                        <div className="align-items-center">
                          <div className="subheader">Velocidad promedio</div>
                        </div>
                        <div className="h2 mb-3">
                          {activity.stats.averageMovingSpeed} km/h
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {activity.stats.normalizedPowerAverage && (
              <div className="col-0">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex">
                      <h3 className="card-title">Rendimiento</h3>
                    </div>
                    <div className="row">
                      <div className="col-6 col-sm-3">
                        <div className="align-items-center ">
                          <div className="subheader ">Grasa consumida</div>
                        </div>
                        <div className="h3 mb-3">
                          {activity.stats.weightLoss} gr.
                        </div>
                      </div>
                      <div className="col-6 col-sm-3">
                        <div className="align-items-center ">
                          <div className="subheader">Calorias</div>
                        </div>
                        <div className="h3 mb-3">
                          {activity.stats.calories} KCal{" "}
                        </div>
                      </div>
                      {activity.stats.relativeEffort && (
                        <div className="col-6 col-sm-3">
                          <div className="align-items-center">
                            <div className="subheader">Esfuerzo relativo</div>
                          </div>
                          <div className="h3 mb-3">
                            {activity.stats.relativeEffort} / 10{" "}
                            <IconFlame
                              size="5"
                              color={
                                activity.stats.relativeEffort > 7
                                  ? "red"
                                  : "orange"
                              }
                              stroke={5}
                              alignmentBaseline="middle"
                            />
                          </div>
                        </div>
                      )}

                      <div className="col-6 col-sm-3">
                        <div className="align-items-center">
                          <div className="subheader">Potencia normalizada</div>
                        </div>
                        <div className="h3 mb-3">
                          {activity.stats.normalizedPowerAverage} w
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!!activity.stats?.totalTime && activity.stats?.movingTime && (
              <div className="col-0">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex">
                      <h3 className="card-title">Tiempos</h3>
                    </div>
                    <p className="mb-3">
                      La actividad duró{" "}
                      <strong>
                        {timestampToTime(activity.stats.totalTime)}
                      </strong>{" "}
                      de los cuales{" "}
                      <strong>
                        {timestampToTime(activity.stats?.movingTime)}
                      </strong>{" "}
                      en movimiento
                    </p>
                    <div className="progress progress-separated mb-3">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          backgroundColor: COLORS.SLOPE.UPHILL,
                          width: `${Math.round(
                            activity.stats.upHillTimePercentage * 100
                          )}%`,
                        }}
                      ></div>
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          backgroundColor: COLORS.SLOPE.DOWNHILL,
                          width: `${Math.round(
                            activity.stats.downHillTimePercentage * 100
                          )}%`,
                        }}
                      ></div>
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          backgroundColor: COLORS.SLOPE.FLAT,
                          width: `${Math.round(
                            activity.stats.flatTimePercentage * 100
                          )}%`,
                        }}
                      ></div>
                    </div>
                    <div className="row">
                      <div className="col-auto d-flex align-items-center pe-2">
                        <span
                          className="legend me-2"
                          style={{ backgroundColor: COLORS.SLOPE.UPHILL }}
                        ></span>
                        <span>Subiendo</span>
                        <span className="d-none d-md-inline d-lg-none d-xxl-inline ms-2 text-muted">
                          {timestampToTime(activity.stats.upHillTime)}
                        </span>
                      </div>
                      <div className="col-auto d-flex align-items-center px-2">
                        <span
                          className="legend me-2"
                          style={{ backgroundColor: COLORS.SLOPE.DOWNHILL }}
                        ></span>
                        <span>Bajando</span>
                        <span className="d-none d-md-inline d-lg-none d-xxl-inline ms-2 text-muted">
                          {timestampToTime(activity.stats.downHillTime)}
                        </span>
                      </div>
                      <div className="col-auto d-flex align-items-center px-2">
                        <span
                          className="legend me-2"
                          style={{ backgroundColor: COLORS.SLOPE.FLAT }}
                        ></span>
                        <span>Plano</span>
                        <span className="d-none d-md-inline d-lg-none d-xxl-inline ms-2 text-muted">
                          {timestampToTime(activity.stats.flatTime)}
                        </span>
                      </div>
                      <div className="col-auto d-flex align-items-center ps-2">
                        <span className="legend me-2"></span>
                        <span>Parado</span>
                        <span className="d-none d-md-inline d-lg-none d-xxl-inline ms-2 text-muted">
                          {timestampToTime(activity.stats.stoppedTime)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-0">
              <div className="card">
                <div className="row">
                  <div
                    className="col-12 col-sm-10"
                    style={{ minHeight: "400px" }}
                  >
                    <OpenMap
                      coords={coords}
                      points={points}
                      color={COLORS.MAP_LINE}
                      fitMapToContent
                    />
                  </div>
                  <div className="col-12 col-sm-2">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-6 col-sm-12">
                          <div className="col-12">
                            <div className="align-items-center ">
                              <div className="subheader small">
                                Altitud máxima
                              </div>
                            </div>
                            <div className="h3 mb-3">
                              {activity.stats.maxAltitude} m
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="align-items-center ">
                              <div className="subheader small">
                                Altitud mínima
                              </div>
                            </div>
                            <div className="h3 mb-3">
                              {activity.stats.minAltitude} m
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="align-items-center ">
                              <div className="subheader small">
                                Ascenso total
                              </div>
                            </div>
                            <div className="h3 mb-3">
                              {activity.stats.totalClimb} m
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="align-items-center ">
                              <div className="subheader small">
                                Descenso total
                              </div>
                            </div>
                            <div className="h3 mb-3">
                              {activity.stats.totalDescend} m
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-sm-12">
                          <div className="col-12">
                            <div className="align-items-center ">
                              <div className="subheader small">
                                Mayor ascenso continuo
                              </div>
                            </div>
                            <div className="h3 mb-3">
                              {activity.stats.longestClimb} m
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="align-items-center ">
                              <div className="subheader small">
                                Mayor descenso continuo
                              </div>
                            </div>
                            <div className="h3 mb-3">
                              {activity.stats.longestDescend} m
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="align-items-center">
                              <div className="subheader">Ascenso / Subida</div>
                            </div>
                            <div className="h3 mb-3">
                              {!!activity.stats.upHillDistance
                                ? Math.round(
                                    activity.stats.upHillDistance / 10
                                  ) /
                                    100 +
                                  " Km"
                                : "-"}
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="align-items-center">
                              <div className="subheader">Descenso / Bajada</div>
                            </div>
                            <div className="h3 mb-3">
                              {!!activity.stats.downHillDistance
                                ? Math.round(
                                    activity.stats.downHillDistance / 10
                                  ) /
                                    100 +
                                  " Km"
                                : "-"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              <div>
                <div className="card ">
                  <div
                    className="card-body row"
                    style={{ paddingBottom: "0px" }}
                  >
                    <div className="col-6">
                      <h3 className="card-title">Detalles</h3>
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                      {activity.stats.hrByTime && (
                        <SelectableButton
                          id={METRICS.HR}
                          checked={true}
                          onChange={updateDetails}
                        >
                          <IconHeart color={COLORS.METRICS.HR} />
                        </SelectableButton>
                      )}
                      <SelectableButton
                        id={METRICS.CADENCE}
                        checked={true}
                        onChange={updateDetails}
                      >
                        <IconSpiral color={COLORS.METRICS.CADENCE} />
                      </SelectableButton>
                      <SelectableButton
                        id={METRICS.TEMPERATURE}
                        checked={true}
                        onChange={updateDetails}
                      >
                        <IconTemperature color={COLORS.METRICS.TEMPERATURE} />
                      </SelectableButton>
                      <SelectableButton
                        id={METRICS.ELEVATION}
                        checked={true}
                        onChange={updateDetails}
                      >
                        <IconMountain color={COLORS.METRICS.ELEVATION} />
                      </SelectableButton>
                    </div>
                  </div>
                  <div className="">{lineChartMemo}</div>
                </div>
              </div>
            }
            <div className="col-12 col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex">
                    <h3 className="card-title">Ascenso</h3>
                  </div>
                  <div className="row">
                    {!!activity.stats.upHillTime && (
                      <div className="col-6 col-sm-3">
                        <div className="align-items-center">
                          <div className="subheader">Tiempo total</div>
                        </div>
                        <div className="h3 mb-3">
                          {timestampToTime(activity.stats.upHillTime)}
                        </div>
                      </div>
                    )}
                    <div className="col-6 col-sm-3">
                      <div className="align-items-center">
                        <div className="subheader">Acumulado</div>
                      </div>
                      <div className="h3 mb-3">
                        {activity.stats.totalClimb} m
                      </div>
                    </div>
                    <div className="col-6 col-sm-3">
                      <div className="align-items-center">
                        <div className="subheader">Mayor ascenso</div>
                      </div>
                      <div className="h3 mb-3">
                        {activity.stats.longestClimb} m
                      </div>
                    </div>
                    {!!activity.stats.averageClimbPace && (
                      <div className="col-6 col-sm-3">
                        <div className="align-items-center">
                          <div className="subheader">Ascenso promedio</div>
                        </div>
                        <div className="h3 mb-3">
                          {activity.stats.averageClimbPace} m/h
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex">
                    <h3 className="card-title">Temperatura</h3>
                  </div>
                  <div className="row">
                    <div className="col-6 col-sm-4">
                      <div className="align-items-center">
                        <div className="subheader">Mínima</div>
                      </div>
                      <div className="h3 mb-3">
                        {!!activity.stats.minTemperature
                          ? activity.stats.minTemperature
                          : "-- "}
                        °
                      </div>
                    </div>
                    <div className="col-6 col-sm-4">
                      <div className="align-items-center">
                        <div className="subheader">Media</div>
                      </div>
                      <div className="h3 mb-3">
                        {!!activity.stats.averageTemperature
                          ? activity.stats.averageTemperature
                          : "--"}
                        °
                      </div>
                    </div>
                    <div className="col-6 col-sm-4">
                      <div className="align-items-center">
                        <div className="subheader">Máxima</div>
                      </div>
                      <div className="h3 mb-3">
                        {!!activity.stats.maxTemperature
                          ? activity.stats.maxTemperature
                          : "--"}
                        °
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!!activity.stats.hrAverage && (
              <div className="col-6 col-sm-3">
                <div className="card">
                  <div className="card-body pb-0">
                    <div className="d-flex">
                      <h3 className="col-6 card-title mb-0">
                        Frecuencia cardiaca
                      </h3>
                      <div className="col-6 text-end">
                        <div className="">
                          <div className="subheader">media</div>
                        </div>
                        <div className="h2 mb-3 text-right">
                          {activity.stats.hrAverage} ppm
                        </div>
                      </div>
                    </div>
                  </div>
                  <BarChart
                    color={COLORS.METRICS.HR}
                    labels={Object.keys(activity.stats.hrByTime)}
                    series={[
                      {
                        name: "HR",
                        data: Object.values(activity.stats.hrByTime),
                      },
                    ]}
                  />
                </div>
              </div>
            )}
            {!!activity.stats.slopeAverage && (
              <div className="col-6 col-sm-3">
                <div className="card">
                  <div className="card-body pb-0">
                    <div className="d-flex">
                      <h3 className="col-6 card-title mb-0">Desnivel</h3>
                      <div className="col-6 text-end">
                        <div className="">
                          <div className="subheader">media</div>
                        </div>
                        <div className="h2 mb-3 text-right">
                          {activity.stats.slopeAverage} %
                        </div>
                      </div>
                    </div>
                  </div>
                  <BarChart
                    color={COLORS.METRICS.ELEVATION}
                    labels={Object.keys(activity.stats.slopeByTime)}
                    series={[
                      {
                        name: "Desnivel",
                        data: Object.values(activity.stats.slopeByTime),
                      },
                    ]}
                  />
                </div>
              </div>
            )}
            {!!activity.stats.cadAverage && (
              <div
                className={`col-6 ${
                  activity.stats.normalizedPowerAverage
                    ? "col-sm-3"
                    : "col-sm-6"
                }`}
              >
                <div className="card">
                  <div className="card-body pb-0">
                    <div className="d-flex">
                      <h3 className="col-6 card-title mb-0">Cadencia</h3>
                      <div className="col-6 text-end">
                        <div className="">
                          <div className="subheader">media</div>
                        </div>
                        <div className="h2 mb-3 text-right">
                          {activity.stats.cadAverage} ppm
                        </div>
                      </div>
                    </div>
                  </div>
                  <BarChart
                    color={COLORS.METRICS.CADENCE}
                    labels={Object.keys(activity.stats.cadenceByTime)}
                    series={[
                      {
                        name: "Cad",
                        data: Object.values(activity.stats.cadenceByTime),
                      },
                    ]}
                  />
                </div>
              </div>
            )}
            {!!activity.stats.normalizedPowerAverage && (
              <div className="col-6 col-sm-3">
                <div className="card">
                  <div className="card-body pb-0">
                    <div className="d-flex">
                      <h3 className="col-6 card-title mb-0">Potencia</h3>
                      <div className="col-6 text-end">
                        <div className="">
                          <div className="subheader">media</div>
                        </div>
                        <div className="h2 mb-3 text-right">
                          {activity.stats.normalizedPowerAverage} W
                        </div>
                      </div>
                    </div>
                  </div>
                  <BarChart
                    color={COLORS.METRICS.POWER}
                    labels={Object.keys(activity.stats.powerByTime)}
                    series={[
                      {
                        name: "Power",
                        data: Object.values(activity.stats.powerByTime),
                      },
                    ]}
                  />
                </div>
              </div>
            )}
            {!!activity.segments && activity.segments.length > 0 && (
              <div className="col-0">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex">
                      <h3 className="card-title">Segmentos</h3>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table card-table table-vcenter ">
                      <thead>
                        <tr>
                          <th>Segmento</th>
                          <th className="text-center">FC media</th>
                          <th className="text-center">Potencia</th>
                          <th className="text-center">Distancia</th>
                          <th className="text-center">Tiempo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {activity.segments.map(
                          (segmentAttempt: any, index: number) => (
                            <tr key={"segment" + index}>
                              <td>{segmentAttempt.segment.name}</td>
                              <td className="text-center">
                                <span className="flag flag-country-us"></span>
                                {segmentAttempt.hrAverage} ppm
                              </td>
                              <td className="text-center">
                                {segmentAttempt.normalizedPowerAverage} W
                              </td>
                              <td className="text-center">
                                {segmentAttempt.totalDistance} Km
                              </td>
                              <td className="text-center">
                                {timestampToTime(segmentAttempt.time)}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
