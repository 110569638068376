import { IconBike, IconEdit, IconStar, IconTrash } from "@tabler/icons";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useEffect, useState } from "react";
import {
  getUser,
  selectBikes,
  getBikes,
  deleteBike,
  updateBike,
} from "../../store/userSlice";
import { selectAuthUser, selectAuthUserId } from "../../store/authSlice";
import { Spinner } from "../../components/spinner/Spinner";
import useConfirmationModal from "../../hooks/useModalConfirmation";
import { MODALS } from "../../util/constants";
import { formatTimestamp } from "../../util/date";
import { useAppDispatch } from "../../hooks/useAppDispatch";

export const Equipment = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectAuthUser);
  const userId = useAppSelector(selectAuthUserId);
  const bikesList = useAppSelector(selectBikes);
  const [saveConfirmation, setSaveConfirmation] = useState(false);
  const { openModalWithComponent, openGenericModal } = useConfirmationModal();

  useEffect(() => {
    dispatch(getUser(userId));
    dispatch(getBikes(null));
  }, [userId, dispatch]);

  const onDeleteBike = async (id: string) => {
    const isConfirmed = await openGenericModal(
      "Elimiar bicicleta?",
      "Esta bicicleta seguirá formando parte de las actividades previas en las que la usaste.",
      "Eliminar bicicleta",
      "Cancelar",
      MODALS.MODALS_TYPES.GENERIC_CONFIRMATION_WARNING
    );

    if (isConfirmed) {
      await dispatch(deleteBike(id));
      dispatch(getBikes(null));
    }
  };

  const addBike = async () => {
    const result = await openModalWithComponent(MODALS.MODALS_TYPES.ADD_BIKE);
    if (result) {
      dispatch(getBikes(null));
    }
  };

  const editBike = async (bike: any) => {
    const result = await openModalWithComponent(
      MODALS.MODALS_TYPES.ADD_BIKE,
      bike
    );
    if (result) {
      dispatch(getBikes(null));
    }
  };

  const setDefault = async (bike: any, isDefault: boolean) => {
    await dispatch(updateBike({ ...bike, default: isDefault }));
    dispatch(getBikes(null));
  };

  useEffect(() => {
    if (saveConfirmation) {
      setTimeout(() => {
        setSaveConfirmation(false);
      }, 1000);
    }
  }, [saveConfirmation]);

  if (!user) {
    return <Spinner />;
  }

  return (
    <div className="card-body">
      <h3>Bicicletas</h3>
      <div className="table-responsive">
        <table className="table table-vcenter card-table">
          <thead>
            <tr>
              <th colSpan={2}>Nombre</th>
              <th>Fecha</th>
              <th>Kms iniciales</th>
              <th className="w-1"></th>
              <th className="w-1"></th>
            </tr>
          </thead>
          <tbody>
            {bikesList && bikesList.bikes && bikesList.bikes.length > 0 ? (
              bikesList.bikes?.map((bike: any) => {
                return (
                  <tr key={bike._id}>
                    <td
                      className="text-muted cursor-pointer"
                      onClick={() => {
                        setDefault(bike, !bike.default);
                      }}
                    >
                      <IconStar
                        className="cursor-pointer"
                        fill={bike.default ? "#000" : "#FFF"}
                        size={"15px"}
                      />
                    </td>
                    <td>
                      <div className="d-flex py-1 align-items-center">
                        <span className="avatar me-2">
                          <IconBike />
                        </span>
                        <div className="flex-fill">
                          <span className="font-weight-medium">
                            {bike.name}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-muted">
                        {formatTimestamp(+bike.creationDate)}
                      </div>
                    </td>
                    <td className="text-muted">{bike.initialKms}</td>
                    <td>
                      <div
                        className="btn col-xl-auto"
                        onClick={() => {
                          editBike(bike);
                        }}
                      >
                        <IconEdit />
                      </div>
                    </td>
                    <td>
                      <button
                        className="btn text-center"
                        onClick={() => onDeleteBike(bike._id)}
                      >
                        <IconTrash />
                      </button>{" "}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={9}
                  className="text-muted text-center cursor-pointer"
                >
                  Sin bicicletas
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="text-end mt-3">
          <span onClick={addBike} className="btn" role="button">
            Añadir bicicleta
          </span>
        </div>
      </div>
    </div>
  );
};
