export const getCurrentYear = (): number => {
  return new Date().getFullYear();
};

export const getCurrentMonth = (): number => {
  const d = new Date();
  const month = d.getMonth();
  return month + 1;
};

export const getCurrentDay = (): number => {
  const d = new Date();
  return d.getDate();
};

export const isLeapYear = (year: number) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const getArrayOfYears = () => {
  const currentYear = getCurrentYear();
  let years = [];
  for (let i = currentYear; i > currentYear - 100; i--) {
    years.push(i);
  }
  return years;
};

export const deconstructDateTime = (
  dateString: string
): { day: number; month: number; year: number } => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return { day, month, year };
};

export const deconstructTimestamp = (dateString: any) => {
  const date = new Date(+dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return { day, month, year };
};

export const formatTimestamp = (timestamp: any) => {
  const { day, month, year } = deconstructTimestamp(timestamp);
  return `${day}-${month}-${year}`;
};

export const getArrayOfMonths = () => {
  return [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
};

export const getArrayOfDays = (month: number, year: number) => {
  const totalDays = new Date(year, month, 0).getDate();
  let days = [];
  for (let i = 1; i < totalDays + 1; i++) {
    days.push(i);
  }
  return days;
};
