import { useAppSelector } from "../../hooks/useAppSelector";
import { selectAuthUserId } from "../../store/authSlice";
import { Spinner } from "../../components/spinner/Spinner";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { VISIBILITY } from "../../util/constants";
import {
  getUser,
  selectCurrentUser,
  selectCurrentUserConfig,
  updateUser,
} from "../../store/userSlice";
import { useEffect, useRef } from "react";
import { IconCheckbox, IconSquare } from "@tabler/icons";

export const Privacy = () => {
  const dispatch = useAppDispatch();
  const timer = useRef<any>(null);
  const userId = useAppSelector(selectAuthUserId);
  const user = useAppSelector(selectCurrentUser);

  useEffect(() => {
    if (!user || user.uid !== userId) {
      dispatch(getUser(userId));
    }
  }, [user, userId, dispatch]);

  const config = useAppSelector(selectCurrentUserConfig);
  const activitiesVisibility =
    config?.privacy?.activitiesVisibility || VISIBILITY.PRIVATE;
  const enableMapPrivacy = config?.privacy?.hideStartAndEnd || false;

  const updateActivitiesVisibility = (visibility: VISIBILITY) => {
    clearTimeout(timer.current);
    const userPrivacy = { ...(config?.privacy || {}) };
    const newPrivacy = { ...userPrivacy, activitiesVisibility: visibility };
    const userData = {
      config: { privacy: newPrivacy },
    };
    timer.current = setTimeout(() => {
      dispatch(updateUser({ userId, userData }));
      timer.current = null;
    }, 500);
  };

  const updateMapPrivacy = (enableMapPrivacy: boolean) => {
    clearTimeout(timer.current);
    const userPrivacy = { ...(config?.privacy || {}) };
    const newPrivacy = {
      ...userPrivacy,
      startAndEndHideDistance: 500,
      hideStartAndEnd: enableMapPrivacy,
    };
    const userData = {
      config: { privacy: newPrivacy },
    };
    timer.current = setTimeout(() => {
      dispatch(updateUser({ userId, userData }));
      timer.current = null;
    }, 1000);
  };

  if (!user) {
    return <Spinner />;
  }

  return (
    <div className="card-body">
      <h3>Privacidad</h3>
      <div className="row mt-4">
        <div className="col-12">
          <span>
            <b>Visibilidad de actividades</b>
          </span>
          <br />
          <br />
          <div
            className="card cursor-pointer"
            onClick={() => updateActivitiesVisibility(VISIBILITY.PUBLIC)}
          >
            <div
              className={`card-body ${
                activitiesVisibility === VISIBILITY.PUBLIC &&
                "border border-2 border-info"
              } `}
            >
              <p>
                <b>
                  {activitiesVisibility === VISIBILITY.PUBLIC ? (
                    <IconCheckbox className="me-1" color="black" />
                  ) : (
                    <IconSquare className="me-1" color="lightgrey" />
                  )}
                  Publica
                </b>
              </p>
              <p>
                Visibles a para cualquier persona, incluso para usuarios no
                registrados en XCLegends. Esta opción te permitirá compartir las
                actividades en cualquier lugar.
              </p>
            </div>
          </div>
          <br />
          <div
            className="card cursor-pointer"
            onClick={() => updateActivitiesVisibility(VISIBILITY.PRIVATE)}
          >
            <div
              className={`card-body ${
                activitiesVisibility === VISIBILITY.PRIVATE &&
                "border border-2 border-info"
              } `}
            >
              <p>
                {activitiesVisibility === VISIBILITY.PRIVATE ? (
                  <IconCheckbox className="me-1" color="black" />
                ) : (
                  <IconSquare className="me-1" color="lightgrey" />
                )}
                <b>Privada</b>
              </p>
              <p>Tus actividades solo las podrás ver tu.</p>
            </div>
          </div>
          <div className="mt-3">
            <label className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                disabled={activitiesVisibility === VISIBILITY.PRIVATE}
                onChange={(e) => updateMapPrivacy(e.target.checked)}
                checked={enableMapPrivacy}
              />
              <span className="form-check-label">
                Ocultar en el mapa 500m. a la redonda del punto de inicio y
                final. Solo tu podrás ver la ruta completa.
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
