import "./SimpleMap.css";
import { useEffect, useRef } from "react";

export interface SimpleMapProps {
  mapId: any;
  lineWidth: number;
  track: Array<{ lon: number; lat: number }>;
}

export const SimpleMap = (props: SimpleMapProps) => {
  const canvasRef = useRef(null);
  const track = props.track;
  const newTrack = track.map((t: any) => {
    return [t.ln, t.lt];
  });

  useEffect(() => {
    const canvas: any = canvasRef.current;
    const ctx = canvas.getContext("2d"); //ctx context
    var myPoints = newTrack;

    var minX: any, minY: any, maxX: any, maxY: any;
    myPoints.forEach((p: any, i: any) => {
      if (i === 0) {
        // if first point
        minX = maxX = p[0];
        minY = maxY = p[1];
      } else {
        minX = Math.min(p[0], minX);
        minY = Math.min(p[1], minY);
        maxX = Math.max(p[0], maxX);
        maxY = Math.max(p[1], maxY);
      }
    });
    // now get the map width and heigth in its local coords
    const mapWidth = maxX - minX + 0.01;
    const mapHeight = maxY - minY + 0.01;
    const mapCenterX = (maxX + minX) / 2;
    const mapCenterY = (maxY + minY) / 2;

    // to find the scale that will fit the canvas get the min scale to fit height or width
    const scale = Math.min(canvas.width / mapWidth, canvas.height / mapHeight);

    // Now you can draw the map centered on the cavas
    ctx.beginPath();
    ctx.lineWidth = props.lineWidth || 5;
    ctx.strokeStyle = "#999";
    myPoints.forEach((p: any) => {
      ctx.lineTo(
        (p[0] - mapCenterX) * scale + canvas.width / 2,
        (mapCenterY - p[1]) * scale + canvas.height / 2
      );
    });
    ctx.stroke();
  }, [newTrack, props.lineWidth]);

  return (
    <canvas
      ref={canvasRef}
      className="canvasSimpleMap"
      id={`SimpleMap${props.mapId}`}
    ></canvas>
  );
};
