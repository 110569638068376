export const cookieExists = ( name: string ) => {

    var cookies = '; ' + document.cookie;
    var splitCookie = cookies.split( '; ' + name + '=' );
    return splitCookie.length === 2;

}

export const expireCookie = ( name: string ) => {

    createCookie( name, 'false', -365 );

}

export const createCookie = ( name: string, value: string, days: number, force = false ) => {

    var myDate = new Date();
    myDate.setMonth( myDate.getDate() + days );
    document.cookie = name + "=" + value + ";expires=" + myDate
        + ";domain=" + window.location.hostname + ";path=/";

};