import React, { useEffect } from "react";
import { Routes } from "./routes";
import { useAppSelector } from "./hooks/useAppSelector";
import {
  setDestinationPath,
  renewAsync,
  selectAuthLoading,
  selectLogged,
  selectLoggedOut,
} from "./store/authSlice";
import { cookieExists } from "./util/cookiesHelpers";
import "./App.css";
import { Spinner } from "./components/spinner/Spinner";
import { getConfig } from "./store/appSlice";
import { useAppDispatch } from "./hooks/useAppDispatch";

export default function App(props: any) {
  const dispatch = useAppDispatch();
  const authLoading = useAppSelector(selectAuthLoading);
  const hasLoggedOut = useAppSelector(selectLoggedOut);
  const logged = useAppSelector(selectLogged);
  const path = window.location.pathname + window.location.search;

  // Store original first url if not logged and not using login/signup/password
  if (
    !logged &&
    path !== "/login" &&
    path !== "/" &&
    path !== "/signup" &&
    path.indexOf("/password") < 0 &&
    !hasLoggedOut
  ) {
    dispatch(setDestinationPath(path));
  }

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      const protocol = window.location.protocol;
      if (protocol === "http:") {
        window.location.assign("https:" + window.location.host);
      }
    }
    if (cookieExists("remember_me")) {
      dispatch(renewAsync());
    }
  }, [dispatch]);

  useEffect(() => {
    if (logged) {
      dispatch(getConfig());
    }
  }, [logged, dispatch]);

  if (authLoading) {
    return (
      <div className="page page-center text-center">
        <div>
          <Spinner />
        </div>
      </div>
    );
  } else {
    return <Routes />;
  }
}
