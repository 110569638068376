import Chart from "react-apexcharts";
import { timestampToTime } from "../../util/dataFormats";

export const BarChart = (props: any) => {
  const timeFormatter = (value: any) => timestampToTime(value);
  return (
    <Chart
      options={{
        chart: {
          id: "basic-bar",
          toolbar: { show: false },
          parentHeightOffset: 0,
          sparkline: { enabled: true },
        },
        xaxis: {
          categories: [],
          labels: {
            show: false,
          },
          axisBorder: { show: false },
          axisTicks: { show: false },
        },
        yaxis: {
          labels: { show: false },
        },
        fill: { colors: [props.color] },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        labels: props.labels,
        grid: {
          show: false,
          padding: { left: 0, right: 0, top: 0, bottom: 0 },
        },
        tooltip: {
          y: {
            formatter: timeFormatter,
          },
          x: {},
        },
      }}
      series={props.series}
      type="bar"
      width="100%"
      height={props.height ? props.height : "200px"}
    />
  );
};
