import { Link } from "react-router-dom";
import { useAppSelector } from "../hooks/useAppSelector";
import { selectLogged } from "../store/authSlice";
import Modal from "../components/modals/Modal";
import { Navbar } from "react-bootstrap";
import { MainLogo } from "../components/logos/mainLogo";

export const PublicLayout = (props: any) => {
  const logged = useAppSelector(selectLogged);

  return (
    <div className="zoom-wrapper">
      <Modal />
      <div>
        <div className="sticky-top">
          <Navbar collapseOnSelect bg="light" expand="md" sticky="top">
            <div className="container-xl">
              <div className="me-3">
                <Link
                  to={logged ? "/timeline" : "/login"}
                  className="text-decoration-none"
                >
                  <MainLogo width="100px" />
                </Link>
              </div>
              <div className="navbar-nav flex-row order-md-last"></div>
            </div>
          </Navbar>
        </div>
        <div className="page-wrapper page-dark">{props.children}</div>
      </div>
    </div>
  );
};
