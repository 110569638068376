export const TermsContent = () => {
  return (
    <>
      <div style={{ margin: "20px" }}>
        <h3>Términos y condiciones</h3>
        <p>
          XCLegends.com es una plataforma gratuita de análisis de actividades
          deportivas, al registrase y usar la aplicación el usuario acepta las
          siguientes condiciones.
        </p>
        <p>
          - No se permite el uso de lenguaje obsceno o irrespetuoso en
          xclegends.com (de ahora en adelante XCL) ni el acoso o las faltas de
          respeto a otros usuarios.
        </p>
        <p>
          - No se permiten datos o actividades deportivas que por cualquier
          motivo puedan estar relacionadas con actividades ilegales, delictivas
          o dañinas.
        </p>
        <p>
          - No se permite monetizar el uso de la aplicación de ninguna forma ni
          vender credenciales de acceso a terceros sin el consentimiento de XCL.
        </p>
        <p>
          - No se permite añadir publicidad ni usar la plataforma para beneficio
          propio.
        </p>
        <p>
          - No se permite ningún tipo de actividad dañina contra la plataforma,
          en caso de ocurrir XCL se reserva el derecho de bloquear o eliminar al
          usuario.
        </p>
        <p>
          - XCL se reserva el derecho a eliminar cualquier actividad o usuario
          en cualquier momento de forma unilateral, sin previo aviso y sin
          justificación.
        </p>
        <p>
          - XCL se reserva el derecho a rescindir en cualquier momento su
          actividad y a eliminar completamente todos los datos guardados sin
          previa notificación al usuario.
        </p>
        <p>
          - XCL no puede asegurar un servicio 100% ininterrumpido y no se hará
          responsable de eventuales cortes de servicio ante problemas técnicos
          de cualquier tipo.
        </p>
        <p>
          - Los datos de un usuario dado de baja o bloqueado por la propia
          plataforma serán eliminados completamente y no recuperables.
        </p>
        <p>
          - Algunos datos computados de estimaciones generales o cálculos sobre
          grupos de usuarios, generados por la plataforma y que no identifican
          al usuario, se mantendrán aunque un usuario haya sido dado de baja.
        </p>
        <p>
          - La aplicación es gratuita y libre de publicidad pero XCL se reserva
          el derecho de añadir publicidad or incorporar un sistema de pago de
          suscripciones en cualquier momento, dando derecho al usuario la
          posibilidad de darse de baja si así lo quisiera.
        </p>
        <p>
          <b>
            El registro en la aplicación no da al usuario ningún derecho ni
            propiedad sobre XCL más allá de sus datos personales, datos de
            equipamiento y actividades subidas.
          </b>
        </p>
        <p>
          <b>
            Al registrarse el usuario acepta todas las condiciones mencionadas
            anteriormente y a hacer un uso legal y responsable de la aplicación.
          </b>
        </p>
        <br />
        <br />
        <p>
          Sobre <b>XCL</b> y su compromiso con el usuario:
        </p>
        <p>
          Nos comprometemos a hacer un uso ético de los datos personales de los
          usuarios, que solo serán usados dentro de la propia plataforma, y que
          bajo ningún concepto serán compartidos con terceros ni comercializados
          de forma expresa.
        </p>
        <p>
          Tu privacidad es importante para nosotros, por lo que nos
          comprometemos a proteger tus datos todo lo posible. Además nos tomamos
          la ciberseguridad muy en serio y hacemos todo lo posible para que la
          plataforma sea segura para ti.
        </p>
        <p>
          Además nos compromentemos a eliminar tus datos completamente si en
          algún momento deseas no seguir siendo parte de la aplicación.
        </p>
        <br />
        <hr />
        <br />
        <h3>Terms and Conditions</h3>
        <p>
          XCLegends.com is a free sports activity analysis platform. By
          registering and using the application, the user accepts the following
          conditions.
        </p>
        <p>
          - The use of obscene or disrespectful language on xclegends.com
          (hereinafter XCL) is not allowed, nor is harassment or disrespect to
          other users.
        </p>
        <p>
          - Data or sports activities that for any reason may be related to
          illegal, criminal or harmful activities is not allowed.
        </p>
        <p>
          - You are not allowed to monetize the use of the application in any
          way or sell access credentials to third parties without the consent of
          XCL.
        </p>
        <p>
          - You are not allowed to add advertising or use the platform for your
          own benefit.
        </p>
        <p>
          - No type of harmful activity is allowed against the platform, if it
          occurs XCL reserves the right to block or eliminate the user.
        </p>
        <p>
          - XCL reserves the right to remove any activity or user at any time
          unilaterally, without notice and without justification.
        </p>
        <p>
          - XCL reserves the right to terminate its activity at any time and to
          completely delete all saved data without prior notification to the
          user.
        </p>
        <p>
          - XCL cannot guarantee a 100% uninterrupted service and will not be
          responsible for eventual service interruptions due to technical
          problems of any kind.
        </p>
        <p>
          - The data of a user terminated or blocked by the platform will be
          completely deleted and not recoverable.
        </p>
        <p>
          - Some data computed from general estimates or calculations about
          groups of users tthat is generated by the platform and that do not
          identify the user, will be maintained even if a user has been
          unsubscribed.
        </p>
        <p>
          - The application is free and ad-free but XCL reserves the right to
          add advertising or incorporate a subscription payment system at any
          time, giving the user the right to unsubscribe if they wish.
        </p>
        <p>
          <b>
            Registration in the application does not give the user any right or
            ownership of XCL beyond their personal data, equipment data and
            uploaded activities.
          </b>
        </p>
        <p>
          <b>
            By registering, the user accepts all the conditions mentioned above
            and to make legal and responsible use of the application.
          </b>
        </p>
        <br />
        <br />
        <p>
          About <b>XCL</b> and its ethical commitment:
        </p>
        <p>
          We promise to make ethical use of users' personal data, which will
          only be used within the platform itself, and at any way will be shared
          to third parties companies nor commercialized.
        </p>
        <p>
          Your privacy is important to us, so we are committed to protect your
          data as much as possible. In addition, we take cybersecurity very
          seriously and we do everything possible to make the platform safe for
          you.
        </p>
        <p>
          We also promise to delete your data completely if at any time you wish
          to no longer be part of the application.
        </p>
      </div>
    </>
  );
};
