import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from './index';

export interface SegmentsState {
  segment: any;
  loading: boolean;
}

export const initialState: SegmentsState = {
  segment: null,
  loading: true
};

export const createSegment = createAsyncThunk(
  'segments/add',
  async ( segmentData: any, { getState } ) => {
    const state: any = getState();
    const jwt = state.auth.jwt;
    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}/api/segments`,
      segmentData,
      { withCredentials: true, headers: { 'x-token': jwt } }
    );
    return response.data;
  }
);

export const segmentsSlice = createSlice( {
  name: 'segments',
  initialState,
  reducers: {
    loading: ( state, action ) => {
      state.loading = true;
    }
  }
} );

export const { loading } = segmentsSlice.actions;

export const selectCurrentSegment = ( state: RootState ) => state.segments.segment;

export default segmentsSlice.reducer;
