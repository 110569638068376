import { LatLng, LatLngBounds } from "leaflet";
import { useMemo, PropsWithChildren } from "react";
import { CircleMarker, Polyline, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";

interface OpenMapContentProps {
  coords: number[][]; // Full array of positions that will form the line
  color: string;
  points?: [[number, number]]; // Selected points
  fitMapToContent?: boolean;
}

export const OpenMapContent = (
  props: OpenMapContentProps & PropsWithChildren
) => {
  const map = useMap();

  const bounds = useMemo(() => {
    const centerLatMin = Math.min(
      ...props.coords.map((point: any) => point[0])
    );
    const centerLatMax = Math.max(
      ...props.coords.map((point: any) => point[0])
    );
    const centerLonMin = Math.min(
      ...props.coords.map((point: any) => point[1])
    );
    const centerLonMax = Math.max(
      ...props.coords.map((point: any) => point[1])
    );
    const corner1 = new LatLng(centerLatMin, centerLonMin);
    const corner2 = new LatLng(centerLatMax, centerLonMax);

    const bounds = new LatLngBounds(corner1, corner2);
    return bounds;
  }, [props.coords]);

  const positions = useMemo(() => {
    return props.coords.map((coords) => new LatLng(coords[0], coords[1]));
  }, [props.coords]);

  if (props.fitMapToContent === true) {
    map.fitBounds(bounds);
  }

  return (
    <>
      {!!props.points &&
        props.points.length > 0 &&
        props.points.map((point: any, index: number) => (
          <CircleMarker
            key={index}
            center={point.center}
            pathOptions={{ fillColor: "blue" }}
            stroke={false}
            radius={10}
          />
        ))}
      <Polyline pathOptions={{ color: props.color }} positions={positions} />
    </>
  );
};
