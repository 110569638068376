import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAppSelector } from "../../hooks/useAppSelector";
import { selectLogged } from "../../store/authSlice";
import { AnalyticsView } from "../analytics/AnalyticsView";

interface ProtectedRouteProps {
  exact?: boolean;
  path: string;
  component: any;
  location?: any;
}

export function ProtectedRoute(props: ProtectedRouteProps) {
  const logged = useAppSelector(selectLogged);

  return logged ? (
    <AnalyticsView to={props.path}>
      <Route {...props} />
    </AnalyticsView>
  ) : (
    <Redirect to="/login" />
  );
}
