import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from './index';
import { createCookie, cookieExists, expireCookie } from '../util/cookiesHelpers';

export interface AuthState {
  error: boolean;
  loading: boolean; // general auth loading
  busyLogin: boolean;
  loggedOut: boolean;
  jwt: string;
  user: any;
  destinationPath: string;
}

export const initialState: AuthState = {
  error: false,
  loading: false,
  busyLogin: false,
  loggedOut: false,
  jwt: '',
  user: null,
  destinationPath: '',
};

export const loginAsync = createAsyncThunk(
  'auth/login',
  async ( loginData: any ) => {
    const { email, password, keeplogged } = loginData;
    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}/api/auth/login`,
      { email, password, keeplogged },
      { withCredentials: true }
    );
    if ( !!keeplogged ) {
      createCookie( 'remember_me', 'true', 365 );
    }
    return response.data;

  }
);

export const renewAsync = createAsyncThunk(
  'auth/renew',
  async () => {

    if ( cookieExists( "remember_me" ) ) {
      const response = await axios.get(
        `${process.env.REACT_APP_API_HOST}/api/auth/renew`,
        { withCredentials: true } ).catch( () => {
          expireCookie( 'remember_me' );
          throw new Error();
        } );
      return response.data;
    }

  }
);

export const logoutAsync = createAsyncThunk(
  'auth/logout',
  async () => {

    await axios.get(
      `${process.env.REACT_APP_API_HOST}/api/auth/logout`,
      { withCredentials: true } );
    expireCookie( 'remember_me' );
    return;

  }
);

export const deleteAccountAsync = createAsyncThunk(
  'auth/deleteAccount',
  async ( _, { getState } ) => {
    const state: any = getState();
    const jwt = state.auth.jwt;
    await axios.delete(
      `${process.env.REACT_APP_API_HOST}/api/auth/delete-account`,
      { withCredentials: true, headers: { 'x-token': jwt } } );
    expireCookie( 'remember_me' );
    return;

  }
);

export const resetPasswordAsync = createAsyncThunk(
  'auth/resetPassword',
  async ( email: string, { getState } ) => {

    const state: any = getState();
    const jwt = state.auth.jwt;
    let data = new FormData();
    data.append( 'email', email );

    return await axios.post(
      `${process.env.REACT_APP_API_HOST}/api/auth/reset-password`,
      data,
      { withCredentials: true, headers: { 'x-token': jwt } }
    ).then( () => {
      return true;
    } ).catch( () => {
      return false;
    } );

  }
);

export const setPasswordAsync = createAsyncThunk(
  'auth/setPassword',
  async ( passwordData: any, { getState } ) => {

    const { password, verificationCode } = passwordData;
    const state: any = getState();
    const jwt = state.auth.jwt;
    let data = new FormData();
    data.append( 'password', password );
    data.append( 'code', verificationCode );

    return await axios.post(
      `${process.env.REACT_APP_API_HOST}/api/auth/set-password`,
      data,
      { withCredentials: true, headers: { 'x-token': jwt } }
    ).then( () => {
      return true;
    } ).catch( () => {
      return false;
    } );

  }
);


export const createUserAsync = createAsyncThunk(
  'auth/addUser',
  async ( userData: any, { getState } ) => {

    const state: any = getState();
    const jwt = state.auth.jwt;
    const { email, password } = userData;
    let data = new FormData();
    data.append( 'email', email );
    data.append( 'password', password );
    data.append( 'name', email.split( '@' )[ 0 ] );

    return await axios.post(
      `${process.env.REACT_APP_API_HOST}/api/users`,
      data,
      { withCredentials: true, headers: { 'x-token': jwt } }
    ).then( () => {
      return true;
    } ).catch( () => {
      return false;
    } );

  }
);

export const authSlice = createSlice( {
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setDestinationPath: ( state, action: PayloadAction<string> ) => {
      state.destinationPath = action.payload
    },
    removeDestinationPath: ( state ) => {
      state.destinationPath = ''
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: ( builder ) => {
    builder
      .addCase( loginAsync.fulfilled, ( state, action: any ) => {
        state.error = false;
        state.jwt = action.payload.jwt;
        state.loggedOut = false;
        state.user = action.payload.user;
        state.busyLogin = false;
      } )
      .addCase( loginAsync.rejected, ( state, action: any ) => {
        state.user = null;
        state.jwt = '';
        state.error = true;
        state.busyLogin = false;
      } )
      .addCase( loginAsync.pending, ( state, action: any ) => {
        state.busyLogin = true;
      } )
      .addCase( renewAsync.pending, ( state, action: any ) => {
        state.loading = true;
      } )
      .addCase( renewAsync.fulfilled, ( state, action: any ) => {
        state.user = action.payload.user;
        state.jwt = action.payload.jwt;
        state.loading = false;
      } )
      .addCase( renewAsync.rejected, ( state, action ) => {
        state.user = null;
        state.jwt = '';
        state.loading = false;
      } )
      .addCase( logoutAsync.fulfilled, ( state, action: any ) => {
        state.destinationPath = '';
        state.jwt = '';
        state.loggedOut = true;
        state.user = null;
      } )
      .addCase( deleteAccountAsync.fulfilled, ( state, action: any ) => {
        state.destinationPath = '';
        state.jwt = '';
        state.loggedOut = true;
        state.user = null;
      } );;
  },
} );

export const { setDestinationPath, removeDestinationPath } = authSlice.actions;

export const selectAuthError = ( state: RootState ) => state.auth.error;
export const selectAuthLoading = ( state: RootState ) => state.auth.loading;
export const selectAuthBusyLogin = ( state: RootState ) => state.auth.busyLogin;
export const selectAuthJWT = ( state: RootState ) => state.auth.jwt;
export const selectAuthUser = ( state: RootState ) => state.auth.user;
export const selectAuthUserId = ( state: RootState ) => state.auth.user.uid;
export const selectDestionationPath = ( state: RootState ) => state.auth.destinationPath;
export const selectLogged = ( state: RootState ) => !!state.auth.user;
export const selectLoggedOut = ( state: RootState ) => state.auth.loggedOut;
export const selectJWTToken =  ( state: RootState ) => state.auth.jwt;

export default authSlice.reducer;
