import React, { useState } from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import useConfirmationModal from "../../hooks/useModalConfirmation";
import { Spinner } from "../../components/spinner/Spinner";
import { IconAlertTriangle, IconPlus } from "@tabler/icons";
import { useEffect } from "react";
import { selectBikes, getBikes } from "../../store/userSlice";
import { Field, Form, Formik } from "formik";
import { updateActivity } from "../../store/activitiesSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { RouteComponentProps } from "react-router-dom";

interface EditActivityProps extends RouteComponentProps<{ id: string }> {
  activity: any;
}

interface bikesList {
  bikes: any[];
  total: number;
}

export const EditActivity = (props: EditActivityProps) => {
  const dispatch = useAppDispatch();
  const { declineModal, confirmModal } = useConfirmationModal();
  const [saving, setSaving] = useState(false);
  const [initialValues, setInitialValues] = useState<{
    bike: string;
    name: string;
  }>({ bike: props.activity.bikeId, name: props.activity.name });
  const bikesList: bikesList = useAppSelector(selectBikes);

  useEffect(() => {
    dispatch(getBikes(null));
  }, [dispatch]);

  useEffect(() => {
    if (!initialValues.bike) {
      let defaultBikeId = props.activity.bikeId || null;
      if (!defaultBikeId) {
        const defaultBike = bikesList.bikes?.find((bike: any) => bike.default);
        if (defaultBike) {
          defaultBikeId = defaultBike._id;
        } else if (bikesList.bikes?.length > 0) {
          defaultBikeId = bikesList.bikes[0]._id;
        }
      }
      setInitialValues({
        bike: defaultBikeId,
        name: props.activity.name,
      });
    }
  }, [bikesList, props.activity, initialValues.bike]);

  const submitActivity = async (values: any) => {
    setSaving(true);
    await dispatch(
      updateActivity({
        name: values.name,
        bikeId: values.bike,
        activityId: props.activity._id,
      })
    );
    setSaving(false);
    confirmModal();
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={submitActivity}
      >
        <Form>
          <div className="modal-header">
            <h5 className="modal-title">Editar actividad</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={declineModal}
              disabled={saving}
            ></button>
          </div>
          <div className="modal-body">
            <div className="alert alert-warning" role="alert">
              <div className="d-flex">
                <div className="me-3">
                  <IconAlertTriangle />
                </div>
                <div>
                  <h4 className="alert-title">Atención!</h4>
                  <div className="text-muted">
                    Al editar la actividad tus estadísticas se volverán a
                    calcular.
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Nombre</label>
              <Field
                type="text"
                id="name"
                name="name"
                className="form-control"
                placeholder="Nombre de la actividad"
                disabled={saving}
              />
            </div>
            {bikesList.bikes?.length > 0 && (
              <div className="mb-3">
                <label className="form-label">Bicicleta</label>
                <Field
                  as="select"
                  id="bike"
                  name="bike"
                  className="form-select"
                  {...{
                    value: !!initialValues.bike
                      ? initialValues.bike
                      : undefined,
                  }}
                >
                  {bikesList.bikes?.map((bike: any) => (
                    <option key={bike._id} value={bike._id}>
                      {bike.name}
                    </option>
                  ))}
                </Field>
              </div>
            )}
          </div>
          <div className="modal-footer">
            {!saving ? (
              <>
                <span
                  className={`btn btn-link link-secondary ${
                    saving && "disabled"
                  }`}
                  onClick={declineModal}
                >
                  Cancelar
                </span>
                <div className="ms-auto">
                  <button
                    type="submit"
                    className={`btn btn-cyan ms-auto`}
                    data-bs-dismiss="modal"
                    disabled={saving}
                  >
                    <IconPlus /> Guardar
                  </button>
                </div>
              </>
            ) : (
              <Spinner size="25px" />
            )}
          </div>
        </Form>
      </Formik>
    </>
  );
};
