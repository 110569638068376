import React, { useState } from 'react';
import * as xml2js from 'xml2js';
import { createActivity, getActivities, resetList } from '../../store/activitiesSlice';
import { useAppSelector } from '../../hooks/useAppSelector';
import useConfirmationModal from '../../hooks/useModalConfirmation';
import { Spinner } from '../../components/spinner/Spinner';
import { IconPlus, IconInfoCircle } from '@tabler/icons';
import { useEffect } from 'react';
import { selectBikes, getBikes } from '../../store/userSlice';
import { Field, Form, Formik } from 'formik';
import { useAppDispatch } from '../../hooks/useAppDispatch';

export const AddActivity = () => {

    const dispatch = useAppDispatch();
    const { declineModal, confirmModal } = useConfirmationModal();
    const [ gpxFile, setGpxFile ] = useState( null );
    const [ loading, setLoading ] = useState( false );
    const [ activityName, setActivityName ] = useState( '' );
    const bikesList = useAppSelector( selectBikes );
    const defaultBike = bikesList.bikes?.find( ( bike: any ) => bike.default );
    const initialValues = { bike: ( defaultBike && defaultBike._id ) || bikesList.bikes?.[ 0 ]?._id };

    useEffect( () => {
        dispatch( getBikes( null ) );
    }, [ dispatch ] );

    const onFileChange = ( e: any ) => {
        setLoading( true );
        const files = e.target.files;
        if ( files[ 0 ] ) {
            const gpxFile = files[ 0 ];
            const reader = new FileReader()
            reader.onload = async ( e: any ) => {
                const text = ( e.target.result );
                xml2js.parseString( text, ( err, result ) => {
                    setGpxFile( gpxFile );
                    setActivityName( result.gpx.trk[ 0 ].name );
                } );

            };
            reader.readAsText( gpxFile )
        }
        setLoading( false );
    }

    const submitActivity = async ( values: any ) => {
        setLoading( true );
        await dispatch( createActivity( { name: activityName, file: gpxFile, bike: values.bike } ) );
        await dispatch( resetList() );
        dispatch( getActivities() );
        setLoading( false );
        confirmModal();
    }

    return <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={submitActivity}
    >
        <Form>
            <div className="modal-header">
                <h5 className="modal-title">Añadir actividad</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={declineModal} disabled={loading}></button>
            </div>
            <div className="modal-body">
                <div className='mb-3'>
                    <label className="form-label">Archivo GPX</label>
                    <input type="file" className="form-control" name="example-text-input" placeholder="Archivo GPX" onChange={( e ) => onFileChange( e )} disabled={loading} />
                </div>
                {!!activityName &&
                    <>
                        <div className='mb-3'>
                            <label className="form-label">Nombre</label>
                            <input type="text" className="form-control" name="example-text-input" placeholder="Nombre de la actividad" onChange={( e ) => { setActivityName( e.target.value ); }} defaultValue={activityName} disabled={loading} />
                        </div>
                        {bikesList.bikes?.length > 0 &&
                            <div className='mb-3'>
                                <label className="form-label">Bicicleta</label>

                                <Field as="select" id="bike" name="bike" className="form-select" {...( defaultBike ? { value: defaultBike._id } : null )} disabled={loading}>
                                    {bikesList.bikes?.map( ( bike: any ) =>
                                        <option key={`bike${bike._id}`} id={bike._id} value={bike._id}>{bike.name}</option>
                                    )}
                                </Field>
                            </div>}
                        {bikesList.bikes?.length === 0 &&
                            <div className="alert alert-info mb-0" role="alert">
                                <div className="d-flex">
                                    <div className='me-3'>
                                        <IconInfoCircle className='text-blue' />
                                    </div>
                                    <div>
                                        <h4 className="alert-title">Mejora el análisis de tus actividades</h4>
                                        <div className="text-muted">Añade tu peso y tu bicicleta a tu perfil de usuario y asignala a esta actividad para obtener datos adicionales de tu rendimiento.</div>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }
            </div>
            <div className="modal-footer">
                {!loading ? <>
                    <button className="btn btn-link link-secondary" onClick={declineModal} disabled={loading}>
                        Cancelar
                    </button>
                    <div className='ms-auto'>
                        <button type='submit' className={`btn btn-cyan ms-auto ${!!!gpxFile && 'disabled'}`} data-bs-dismiss="modal" disabled={loading} >
                            <IconPlus /> Crear actividad
                        </button>
                    </div></> :
                    <Spinner size='25px' />}
            </div>
        </Form>
    </Formik>

}
