import { Route, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Login } from "./containers/auth/Login";
import { ProtectedRoute } from "./components/routing/ProtectedRoute";
import { Timeline } from "./containers/timeline/Timeline";
import { Layout } from "./layouts/Layout";
import { ActivityDetail } from "./containers/activities/ActivityDetail";
import { Settings } from "./containers/settings/Settings";
import { Signup } from "./containers/auth/Signup";
import { ResetPassword } from "./containers/auth/ResetPassword";
import { SendPasswordEmail } from "./containers/auth/SendPasswordEmail";
import { Profile } from "./containers/profile/Profile";
import { CreateSegment } from "./containers/activities/CreateSegment";
import { TermsContent } from "./containers/auth/TermsContent";
import { GarminConnection } from "./containers/auth/Connection";
import { PublicLayout } from "./layouts/PublicLayout";

export const history = createBrowserHistory();

export const Routes = () => {
  const publicRoutes = (
    <Route path="/public/*">
      <PublicLayout>
        <Route exact path="/public/activity/:id" component={ActivityDetail} />
      </PublicLayout>
    </Route>
  );

  const privateRoutes = (
    <Route>
      <Layout>
        <Route exact path={["/", "/login"]} component={Login} />
        <Route exact path={"/signup"} component={Signup} />
        <Route exact path={["/password"]} component={SendPasswordEmail} />
        <Route exact path={["/password/:code"]} component={ResetPassword} />
        <Route exact path={["/terms"]} component={TermsContent} />
        <ProtectedRoute exact path="/timeline" component={Timeline} />
        <ProtectedRoute exact path="/profile" component={Profile} />
        <ProtectedRoute exact path="/activity/:id" component={ActivityDetail} />
        <ProtectedRoute path="/settings" component={Settings} />
        <ProtectedRoute exact path="/segment/:id" component={CreateSegment} />
        <ProtectedRoute
          exact
          path="/connection/garmin"
          component={GarminConnection}
        />
      </Layout>
    </Route>
  );

  return (
    <Router history={history}>
      <Switch>
        {publicRoutes}
        {privateRoutes}
      </Switch>
    </Router>
  );
};
