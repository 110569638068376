import { PropsWithChildren, useState } from "react";
import "./SelectableButton.css";

interface SelectableButtonProps {
  id: string;
  checked: boolean;
  onChange?: (id: string, value: boolean) => void;
}

export const SelectableButton = (
  props: SelectableButtonProps & PropsWithChildren
) => {
  const [checked, setChecked] = useState<boolean>(props.checked);

  const onChange = () => {
    setChecked(!checked);
    if (!!props.onChange) {
      props.onChange(props.id, !checked);
    }
  };

  return (
    <label className="form-selectgroup-item ms-1">
      <input
        id={props.id}
        type="checkbox"
        value={props.id}
        className="form-selectgroup-input"
        defaultChecked={checked}
        onChange={onChange}
      />
      <span className="form-selectgroup-label">{props.children}</span>
    </label>
  );
};
