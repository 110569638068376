import { useEffect, PropsWithChildren } from "react";
import ReactGA from "react-ga4";

interface AnalyticsProps {
  to: string;
}

export const AnalyticsView = (props: AnalyticsProps & PropsWithChildren) => {
  useEffect(() => {
    if (!ReactGA.isInitialized) {
      ReactGA.initialize([
        {
          trackingId: "G-YLEHTFKXFT",
        },
      ]);
    }
    ReactGA.send({ hitType: "pageview", page: props.to });
  }, [props.to]);

  return <>{props.children}</>;
};
