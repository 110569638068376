import { useAppSelector } from "../../hooks/useAppSelector";
import { selectAuthUserId, selectJWTToken } from "../../store/authSlice";
import { Spinner } from "../../components/spinner/Spinner";
import axios from "axios";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import useConfirmationModal from "../../hooks/useModalConfirmation";
import { MODALS } from "../../util/constants";
import {
  getUser,
  removeGarminAccount,
  selectCurrentUser,
} from "../../store/userSlice";

export const Connections = () => {
  const userId = useAppSelector(selectAuthUserId);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const user = useAppSelector(selectCurrentUser);
  const garminConnected = !!user?.connections?.garmin?.connected;

  const jwt = useAppSelector(selectJWTToken);
  const { openGenericModal } = useConfirmationModal();

  useEffect(() => {
    if (!user || user.uid !== userId) {
      dispatch(getUser(userId));
    }
  }, [user, userId, dispatch]);

  if (!user) {
    return <Spinner />;
  }

  const disconnectGarminAccount = async () => {
    const isConfirmed = await openGenericModal(
      "Desconectar cuenta de Garmin?",
      "Atención. Tus actvidades de Garmin dejarán de sincronizarse automáticamente. ¿Estás seguro?",
      "DESCONECTAR",
      "Volver",
      MODALS.MODALS_TYPES.GENERIC_CONFIRMATION_WARNING
    );

    if (isConfirmed) {
      await dispatch(removeGarminAccount());
      await dispatch(getUser(userId));
    }
  };

  const connectGarmin = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_HOST}/api/connections/garmin/request`, {
        withCredentials: true,
        headers: { "x-token": jwt },
      })
      .then(async (result) => {
        const { data } = result;
        if (!!data && !!data.oauthToken) {
          window.location.href = `https://connect.garmin.com/oauthConfirm?oauth_token=${data.oauthToken}&oauth_callback=${process.env.REACT_APP_UI_HOST}/connection/garmin`;
        } else {
          console.log("ERROR, problems with the token");
        }
      })
      .catch((e) => {
        console.log("Garmin ERROR", e);
        setLoading(false);
      });
  };

  return (
    <div className="card-body">
      <h3>Conexiones</h3>
      <div className="row mt-4">
        <div className="col-2 col-sm-1">
          <img src="/assets/garmin_logo.png" width={"100%"} alt="Garmin" />
        </div>
        <div className="col-10 col-sm-11">
          <h3>Garmin</h3>
          <p>
            Sincroniza tu cuenta de Garmin para que tus actividades se suban
            automáticamente a XCLegends.
          </p>
          {loading ? (
            <Spinner size="25px" />
          ) : (
            <>
              {!garminConnected ? (
                <button
                  disabled={loading}
                  className="btn btn-cyan"
                  onClick={connectGarmin}
                >
                  Conectar con Garmin{" "}
                </button>
              ) : (
                <button
                  className="btn btn-cyan"
                  onClick={disconnectGarminAccount}
                >
                  Desconnectar{" "}
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
