import { useAppSelector } from "../../hooks/useAppSelector";
import { Link } from "react-router-dom";
import {
  loginAsync,
  selectLogged,
  selectDestionationPath,
  selectAuthError,
  selectAuthBusyLogin,
} from "../../store/authSlice";
import { Redirect } from "react-router";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { Spinner } from "../../components/spinner/Spinner";
import "./Login.css";
import { MainLogo } from "../../components/logos/mainLogo";

export const Login = () => {
  const logged = useAppSelector(selectLogged);
  const authError = useAppSelector(selectAuthError);
  const dispatch = useAppDispatch();
  const destinationPath = useAppSelector(selectDestionationPath);
  const busyLogin = useAppSelector(selectAuthBusyLogin);

  const submit = async (event: any) => {
    event.preventDefault();
    dispatch(
      loginAsync({
        email: event.target.email.value,
        password: event.target.password.value,
        keeplogged: event.target.keeplogged.checked,
      })
    );
  };

  if (logged) {
    return <Redirect to={destinationPath ? destinationPath : "/timeline"} />;
  } else {
    return (
      <div className="login-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-6 page page-center">
              <div className="container-tight py-4">
                <form
                  className="card card-md"
                  autoComplete="off"
                  onSubmit={(e) => submit(e)}
                >
                  <div className="card-body">
                    <div className="text-center mb-1">
                      <MainLogo width="40%" />
                    </div>
                    <h2 className="card-title text-center mb-2">
                      Iniciar sesión
                    </h2>
                    <p className="text-center">
                      XCLegends analiza tu actividad fisica y te ofrece métricas
                      avanzadas y análisis de tu datos para que no te pierdas
                      nada.
                    </p>
                    <div className="mb-3">
                      <label className="form-label">Email</label>
                      <input
                        id="email"
                        type="email"
                        className="form-control"
                        placeholder="Enter email"
                        defaultValue=""
                      />
                    </div>
                    <div className="mb-2">
                      <label className="form-label">Contraseña</label>
                      <div className="input-group input-group-flat">
                        <input
                          id="password"
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          autoComplete="off"
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="mb-2">
                      <label className="form-check">
                        <input
                          id="keeplogged"
                          type="checkbox"
                          className="form-check-input"
                          defaultChecked
                        />
                        <label
                          className="form-check-label"
                          htmlFor="keeplogged"
                        >
                          Mantener sessión en este dispositivo
                        </label>
                      </label>
                    </div>
                    {authError && (
                      <div
                        className="alert alert-danger border-danger text-danger mt-4"
                        role="alert"
                      >
                        El usario y/o contraseña no son válidos.
                      </div>
                    )}
                    <div className="form-footer">
                      <button
                        type="submit"
                        disabled={busyLogin}
                        className="btn btn-cyan w-100"
                      >
                        {busyLogin ? <Spinner size="20px" /> : "Iniciar"}
                      </button>
                    </div>
                    <div className="mt-2 text-center">
                      <Link to="/password">Olvidé mi contraseña</Link>
                    </div>
                    <div className="mt-4 text-center">
                      No tienes cuenta?{" "}
                      <Link to="signup" tabIndex={-1}>
                        Regístrate
                      </Link>
                    </div>
                    <div
                      className="mt-4 text-center"
                      style={{ fontSize: "0.9em" }}
                    >
                      <Link to="terms" target={"_blank"} tabIndex={-1}>
                        Términos y condiciones
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-6 d-none d-md-block">
              <div className="demo-img-container">
                <img
                  className="demo-img demo-img3"
                  src="/assets/xcl_demo3.png"
                  alt="XC Legends"
                />
                <img
                  className="demo-img demo-img1"
                  src="/assets/xcl_demo1.png"
                  alt="XC Legends"
                />
                <img
                  className="demo-img demo-img2"
                  src="/assets/xcl_demo2.png"
                  alt="XC Legends"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
